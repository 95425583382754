import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Configuration} from "../../assets/Configuration";
import {TenantModel} from '../models/settings/tenant.model';

@Injectable({
    providedIn: 'root',
})
export class GeneralSettingsService {

    private httpClientWithoutInterceptor: HttpClient;

    constructor(private httpClient: HttpClient, private httpBackend: HttpBackend) {
        this.httpClientWithoutInterceptor = new HttpClient(httpBackend);
    }

    public getTenant(): Observable<TenantModel> {
        return this.httpClient.get<TenantModel>(Configuration.getApiBaseUrl() + '/tenant');
    }

    public getTenantDomains(): Observable<string[]> {
        return this.httpClient.get<string[]>(Configuration.getApiBaseUrl() + '/tenant/domains');
    }

    public updateSettings(settingsModel: TenantModel): Observable<TenantModel> {
        return this.httpClient.post<TenantModel>(Configuration.getApiBaseUrl() + '/tenant', settingsModel);
    }

    public getLoginImage(loginAlias: String): Observable<string> {
        return this.httpClientWithoutInterceptor.get<string>(Configuration.getApiBaseUrl() + '/tenantanonymous/login-image-by-alias?alias=' + loginAlias, {responseType: 'text' as 'json'});
    }
}
