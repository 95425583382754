<div class="training-info" *ngIf="training">
  <app-page>
    <div class="top-cta">
      <dx-button
        [text]="'COMMON.BACK' | translate"
        icon="back"
        type="default"
        class="integration-btn"
        (click)="navigateBack()"
      >
      </dx-button>
      <div class="right-actions">
        <div *ngIf="!isTrainingCatalogue && isBookmarked">
          <div (click)="toggleBookmark()">
            <i
              *ngIf="isBookmarked"
              class="dx-icon dx-svg-icon svg-red"
              [innerHTML]="getBookmarkIcon()"
            ></i>
          </div>
        </div>
        <div *ngIf="isTrainingCatalogue" class="bookmark-extended">
          <div (click)="toggleBookmark()">
            <i
              *ngIf="isBookmarked"
              class="dx-icon dx-svg-icon svg-red"
              [innerHTML]="getBookmarkIcon()"
            ></i>
            <i
              *ngIf="!isBookmarked"
              class="dx-icon dx-svg-icon"
              [innerHTML]="getBookmarkOutlinedIcon()"
            ></i>
          </div>
          {{
            !isBookmarked
              ? ("TRAINING-CATALOGUE.BOOKMARK-EVENT" | translate)
              : ("TRAINING-CATALOGUE.UNBOOKMARK-EVENT" | translate)
          }}
        </div>
        <div *ngIf="!isOldTraining">
          <dx-button
            *ngIf="memberStatus !== 1 && !isAdmin"
            [text]="'MY-TRAININGS.OVERVIEW.ACCEPT-INVITATION' | translate"
            type="normal"
            class="integration-btn"
            (click)="onAcceptInvitationClick()"
          ></dx-button>
          <ng-container *ngIf="memberStatus === 1">
            <dx-drop-down-button
              *ngIf="!isAdmin && isAddedToCalendar"
              [splitButton]="true"
              [text]="'MY-TRAININGS.OVERVIEW.DECLINE-INVITATION' | translate"
              stylingMode="contained"
              [type]="'danger'"
              [dropDownOptions]="{ width: 230 }"
              (onButtonClick)="onDeclineInvitationClick()"
            >
              <dxi-item (click)="onDownloadIcsClick()">
                {{ "MY-TRAININGS.OVERVIEW.SAVE-CALENDAR" | translate }}
              </dxi-item>
            </dx-drop-down-button>
            <dx-drop-down-button
              *ngIf="!isAddedToCalendar"
              [splitButton]="true"
              [text]="'MY-TRAININGS.OVERVIEW.SAVE-TO-CALENDAR' | translate"
              stylingMode="contained"
              [type]="'normal'"
              [dropDownOptions]="{ width: 230 }"
              (onButtonClick)="addToCalendar()"
            >
              <dxi-item (click)="onDeclineInvitationClick()">
                {{ "MY-TRAININGS.OVERVIEW.DECLINE-INVITATION" | translate }}
              </dxi-item>
              <dxi-item *ngIf="isAdmin" (click)="onAdminViewClick()">
                {{ "COMMON.ADMIN-VIEW" | translate }}
              </dxi-item>
            </dx-drop-down-button>
            <dx-drop-down-button
              *ngIf="isAddedToCalendar && isAdmin"
              [splitButton]="true"
              [text]="'MY-TRAININGS.OVERVIEW.DECLINE-INVITATION' | translate"
              stylingMode="contained"
              [type]="'danger'"
              [dropDownOptions]="{ width: 230 }"
              (onButtonClick)="onDeclineInvitationClick()"
            >
              <dxi-item (click)="onAdminViewClick()">
                {{ "COMMON.ADMIN-VIEW" | translate }}
              </dxi-item>
              <dxi-item (click)="onDownloadIcsClick()">
                {{ "MY-TRAININGS.OVERVIEW.SAVE-CALENDAR" | translate }}
              </dxi-item>
            </dx-drop-down-button>
          </ng-container>
          <dx-drop-down-button
            *ngIf="memberStatus !== 1 && isAdmin"
            [splitButton]="true"
            [text]="'MY-TRAININGS.OVERVIEW.ACCEPT-INVITATION' | translate"
            stylingMode="contained"
            [type]="'normal'"
            [dropDownOptions]="{ width: 230 }"
            (onButtonClick)="onAcceptInvitationClick()"
          >
            <dxi-item (click)="onAdminViewClick()">
              {{ "COMMON.ADMIN-VIEW" | translate }}
            </dxi-item>
          </dx-drop-down-button>
        </div>
      </div>
    </div>
    <div class="training-heading">
      <div class="training-details">
        <div class="training-name">{{ training.training.name }}</div>
        <div class="training-stats">
          <div class="training-stats-item">
            {{ "COMMON.FIELDS.TRAINING-TYPE" | translate }}: {{ trainingType }}
          </div>
          <div
            class="training-stats-item"
            *ngIf="training.training.trainingType == 0"
          >
            {{ "COMMON.FIELDS.IDD-TIME-CREDIT" | translate }}:
            {{ durationString }}
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-lg-5">
        <div class="training-image-container">
          <img
            class="training-image"
            [src]="training.training.imageUrl"
            appTrainingImageFallback
          />
        </div>
      </div>
      <div class="col-md-12 col-lg-7">
        <div class="training-widget">
          <div class="training-description">
            {{ training.training.description! }}
          </div>
          <div class="training-widget-item">
            <div class="training-widget-badge">
              <i
                class="dx-icon dx-svg-icon"
                [innerHTML]="getCalendarIcon()"
              ></i>
              {{ "COMMON.DATE" | translate }}:
            </div>
            <div class="training-widget-value">
              <div
                *ngFor="let date of training.dates"
                class="training-dates-block"
              >
                <div class="training-dates-block-date">
                  {{
                    training.dates[0]
                      | trainingDate : translateService.currentLang
                  }}
                </div>
                <div *ngIf="translateService.currentLang == 'en'">
                  {{ training.dates[0].start | date : "hh:mm a" }} -
                  {{ training.dates[0].end | date : "hh:mm a" }}
                </div>
                <div *ngIf="translateService.currentLang == 'de'">
                  {{ training.dates[0].start | date : "HH:mm" }} -
                  {{ training.dates[0].end | date : "HH:mm" }}
                </div>
              </div>
            </div>
          </div>
          <div class="training-widget-item">
            <div class="training-widget-badge">
              <i class="dx-icon-map dx-svg-icon"></i>
              {{ "COMMON.FIELDS.LOCATION" | translate }}:
            </div>
            <div class="training-widget-value">
              <span
                *ngIf="
                  training.training.trainingLocation.type ==
                  TrainingLocationType.Web
                "
              >
                Web Webinar
              </span>
              <span
                *ngIf="
                  training.training.trainingLocation.type ==
                  TrainingLocationType.Webex
                "
              >
                Webex Webinar
              </span>
              <span
                *ngIf="
                  training.training.trainingLocation.type ==
                  TrainingLocationType.Webinar
                "
              >
                Webinar
              </span>
              <span
                *ngIf="
                  training.training.trainingLocation.type ==
                  TrainingLocationType.GotoMeeting
                "
              >
                GoToMeeting Webinar
              </span>
              <span
                *ngIf="
                  training.training.trainingLocation.type ==
                  TrainingLocationType.MsTeams
                "
              >
                Microsoft Teams Webinar
              </span>
              <span
                *ngIf="
                  training.training.trainingLocation.type ==
                  TrainingLocationType.GoToWebinar
                "
              >
                GoToWebinar Webinar
              </span>
              <span
                *ngIf="
                  training.training.trainingLocation.type ==
                  TrainingLocationType.Address
                "
              >
                {{ training.training.trainingLocation.location }}
              </span>
            </div>
          </div>
          <div
            *ngIf="
              training.training.trainingLocation.type !==
                TrainingLocationType.Address && memberStatus === 1
            "
            class="training-widget-item"
          >
            <div class="training-widget-badge">
              <i class="dx-icon-map dx-svg-icon"></i>
              {{ "COMMON.FIELDS.JOIN-URL" | translate }}:
            </div>
            <div class="training-widget-value">
              <a [href]="training.training.trainingLocation.suffix">{{
                joinUrl || training.training.trainingLocation.suffix
              }}</a>
            </div>
          </div>
          <div class="training-widget-item" *ngIf="trainingCategories">
            <div class="training-widget-badge category-badge">
              <i
                class="dx-icon dx-svg-icon"
                [innerHTML]="getCategoryIcon()"
              ></i>
              {{ trainingCategories }}
            </div>
          </div>
          <!-- <div
            *ngIf="
              training.training.trainingLocation.type ==
              TrainingLocationType.GoToWebinar
            "
            class="location-image-container"
          >
            <img class="location-image" src="assets/img/gotowebinar-logo.jpg" />
          </div>
          <div
            *ngIf="
              training.training.trainingLocation.type ==
              TrainingLocationType.Webex
            "
            class="location-image-container"
          >
            <img class="location-image" src="assets/img/webex-logo.jpg" />
          </div> -->
        </div>
      </div>
    </div>
    <div class="training-description-widget">
      <div class="training-widget-title">
        {{ "COMMON.FIELDS.DESCRIPTION" | translate }}
      </div>
      <dx-form
        class="course-description-form"
        labelLocation="top"
        [showColonAfterLabel]="false"
        [formData]="training"
      >
        <dxi-item
          dataField="training.descriptionExtended"
          label=""
          editorType="dxHtmlEditor"
          [editorOptions]="{
            label: '',
            height: 'auto',
            readOnly: true
          }"
        >
          <dxo-label text=" "></dxo-label>
        </dxi-item>
      </dx-form>
    </div>
    <div class="row">
      <div class="col-md-12 col-lg-6">
        <div class="training-widget no-padding">
          <div class="training-widget-item">
            <div class="training-widget-badge">
              <i class="dx-icon-map dx-svg-icon"></i>
              {{ "COMMON.FIELDS.LOCATION" | translate }}:
            </div>
            <div class="training-widget-value">
              <span
                *ngIf="
                  training.training.trainingLocation.type ==
                  TrainingLocationType.Web
                "
              >
                Web Webinar
              </span>
              <span
                *ngIf="
                  training.training.trainingLocation.type ==
                  TrainingLocationType.Webex
                "
              >
                Webex Webinar
              </span>
              <span
                *ngIf="
                  training.training.trainingLocation.type ==
                  TrainingLocationType.Webinar
                "
              >
                Webinar
              </span>
              <span
                *ngIf="
                  training.training.trainingLocation.type ==
                  TrainingLocationType.GotoMeeting
                "
              >
                GoToMeeting Webinar
              </span>
              <span
                *ngIf="
                  training.training.trainingLocation.type ==
                  TrainingLocationType.MsTeams
                "
              >
                Microsoft Teams Webinar
              </span>
              <span
                *ngIf="
                  training.training.trainingLocation.type ==
                  TrainingLocationType.GoToWebinar
                "
              >
                GoToWebinar Webinar
              </span>
              <span
                *ngIf="
                  training.training.trainingLocation.type ==
                  TrainingLocationType.Address
                "
              >
                {{ training.training.trainingLocation.location }}
              </span>
            </div>
          </div>
          <div
            *ngIf="
              training.training.trainingLocation.type !==
                TrainingLocationType.Address && memberStatus === 1
            "
            class="training-widget-item"
          >
            <div class="training-widget-badge">
              <i class="dx-icon-map dx-svg-icon"></i>
              {{ "COMMON.FIELDS.JOIN-URL" | translate }}:
            </div>
            <div class="training-widget-value">
              <a [href]="training.training.trainingLocation.suffix">{{
                joinUrl || training.training.trainingLocation.suffix
              }}</a>
            </div>
          </div>
        </div>
        <div
          *ngIf="
            training.training.trainingLocation.type ===
            TrainingLocationType.Address
          "
          class="training-location-title"
        >
          {{ "COMMON.FIELDS.LOCATION-DETAILS" | translate }}
        </div>
        <div
          *ngIf="
            training.training.trainingLocation.type ===
            TrainingLocationType.Address
          "
        >
          {{ training.training.trainingLocation.suffix! }}
        </div>
      </div>
      <div class="col-md-12 col-lg-6">
        <div
          *ngIf="
            training.training.trainingLocation.type ==
            TrainingLocationType.Address
          "
        >
          <ng-container
            *ngIf="
              training.training.trainingLocation.latitude &&
              training.training.trainingLocation.longitude
            "
          >
            <app-google-maps
              class="my-3"
              [location]="training.training.trainingLocation"
            ></app-google-maps>
          </ng-container>
        </div>
        <div
          *ngIf="
            training.training.trainingLocation.type ==
            TrainingLocationType.GoToWebinar
          "
          class="location-image-container"
        >
          <img class="location-image" src="assets/img/gotowebinar-logo.jpg" />
        </div>
        <div
          *ngIf="
            training.training.trainingLocation.type ==
            TrainingLocationType.Webex
          "
          class="location-image-container"
        >
          <img class="location-image" src="assets/img/webex-logo.jpg" />
        </div>
      </div>
    </div>
  </app-page>
</div>

<app-training-save-calendar-modal
  *ngIf="showSaveCalendarModal"
  [(showModal)]="showSaveCalendarModal"
  [trainingId]="trainingId"
  (submit)="addToCalendar()"
></app-training-save-calendar-modal>
