import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { CommonAxisSettings } from 'devextreme/viz/chart';
import { switchMap } from 'rxjs';
import { TrainingFeedback } from 'src/app/models/training/training-feedback.model';
import { LoadingService } from '../../../services/loading.service';
import { TrainingService } from '../../../services/training.service';

@Component({
  selector: 'app-training-feedback',
  templateUrl: './training-feedback.component.html',
  styleUrl: './training-feedback.component.scss',
})
export class TrainingFeedbackComponent implements OnInit {
  trainingId!: string;
  feedback: TrainingFeedback | null = null;

  commonAxisSettings: CommonAxisSettings = {
    color: '#dddddd',
    grid: { color: '#dddddd' },
    tick: { visible: true, color: '#dddddd' },
    aggregatedPointsPosition: 'crossTicks',
    label: {
      font: {
        color: '#000',
        size: 10,
      },
      visible: true
    },
  };

  pagesData: {
    title: string;
    questions: {
      question: string;
      averageScore?: number;
      chartData?: { id: number; value: number }[];
      answers?: string[];
      maxChartData?: number;
    }[];
    averageScore?: number;
  }[] = [];

  customizeText = (arg: any) =>
    Number(arg.point.height) > 15 ? `${arg.valueText}` : undefined;

  constructor(
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService,
    private trainingService: TrainingService,
    private router: Router,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params
      .pipe(
        switchMap(param => {
          const id = param['id'];
          this.trainingId = id;

          return this.loadingService.load(
            this.trainingService.getTrainingFeedback(id),
          );
        }),
      )
      .subscribe(feedback => {
        this.feedback = feedback;

        this.pagesData = feedback.pages.map(page => ({
          title: page.title,
          questions: page.questions.map(question => {
            const questionData: {
              question: string;
              averageScore?: number;
              chartData?: { id: number; value: number }[];
              answers?: string[];
              maxChartData?: number;
            } = {} as any;
            if (question.type === 1) {
              questionData.question = question.text;
              questionData.chartData = new Array(question.scaleEnd)
                .fill(1)
                .map((_, index) => {
                  return {
                    id: index + 1,
                    value: question.answers.reduce((acc, answer) => {
                      if (answer.value === index + 1) {
                        acc++;
                      }
                      return acc;
                    }, 0),
                  };
                });

              questionData.averageScore =
                question.answers.reduce((acc: number, item) => {
                  acc += item.value;
                  return acc;
                }, 0) / question.answers.length;
              questionData.maxChartData = Math.max(
                ...questionData.chartData.map(item => item.value),
              );
            }

            if (question.type === 0) {
              questionData.question = question.text;
              questionData.answers = question.answers.map(
                answer => answer.text,
              );
            }

            return questionData;
          }),
        }));

        this.pagesData = this.pagesData.map(page => {
          return {
            ...page,
            averageScore:
              page.questions.reduce((acc: number, item) => {
                if (item.averageScore) acc += item.averageScore || 0;
                return acc;
              }, 0) / page.questions.length,
          };
        });
      });
  }

  navigateBack() {
    this.location.back();
  }
}
