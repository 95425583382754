<app-page>
  <app-page-title title="{{ 'TRAINING-CALENDAR.TITLE' | translate }}">
    <!-- <form [formGroup]="filterForm" class="flex-gap-1rem flex-center-between">
      <div class="drop-down-width">
        <app-organisation-unit-tree-drop-down
          [organisationUnits]="locations$"
          formControlName="organisationUnitLocationIds"
        >
        </app-organisation-unit-tree-drop-down>
      </div>
      <div class="drop-down-width">
        <app-training-group-drop-down
          [trainingGroups]="trainingGroups$"
          formControlName="trainingGroupIds"
        ></app-training-group-drop-down>
      </div>
    </form> -->
  </app-page-title>
  <div class="calendar-wrapper">
    <dx-scheduler
      [dataSource]="schedulerData"
      [remoteFiltering]="true"
      [(currentView)]="currentView"
      [customizeDateNavigatorText]="customizeDateNavigatorText"
      [currentDate]="currentDate"
      [startDayHour]="8"
      [endDayHour]="19"
      appointmentTemplate="appointment-template"
      [showAllDayPanel]="true"
      dataCellTemplate="dataCellTemplate"
      allDayPanelMode="hidden"
      [showCurrentTimeIndicator]="false"
      [firstDayOfWeek]="1"
      (onAppointmentClick)="redirectToDetailPage($event)"
    >
      <dxi-view type="month"></dxi-view>
      <dxi-view
        type="week"
        dateCellTemplate="dateCellTemplate"
        showAllAppointmentsAtTimeCells="true"
      ></dxi-view>
      <!-- <dxi-view type="day" showAllAppointmentsAtTimeCells="true"></dxi-view> -->
      <dxo-editing
        #editingOptions
        [allowAdding]="false"
        [allowDeleting]="false"
        [allowUpdating]="false"
        [allowResizing]="false"
        [allowDragging]="false"
      >
      </dxo-editing>
      <div
        *dxTemplate="let model of 'appointment-template'"
        class="test-appointment"
      >
        <div class="appointment-preview">
          <div class="appointment-top">
            <strong>
              {{ formatTime(model.appointmentData.startDate | date : "H:mm") }}
            </strong>
            <div class="appointment-desc">
              {{ model.appointmentData.text }}
            </div>
          </div>
          <div class="appointment-bottom">
            {{ model.appointmentData.startDate | date : "HH:mm a" }} -
            {{ model.appointmentData.endDate | date : "HH:mm a" }}
          </div>
        </div>
      </div>
      <div
        *dxTemplate="let cellData of 'dataCellTemplate'"
        class="employee-weekend"
        #dayCellTmpl
      >
        <div class="day-cell">
          {{ cellData.text }}
        </div>
      </div>
      <div *dxTemplate="let data of 'dateCellTemplate'">
        <b>{{ data.date.getDate() }}</b>
        {{
          ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][data.date.getDay()]
        }}
      </div>
      <!-- 
      //appointmentTooltipTemplate="appointmentTooltipTemplate"
      <ng-container *dxTemplate="let data of 'appointmentTooltipTemplate'">
        <a
          class="dx-tooltip-appointment-item"
          [routerLink]="['/my-trainings/training/' + data.appointmentData.id]"
        >
          <div class="dx-tooltip-appointment-item-marker">
            <div class="dx-tooltip-appointment-item-marker-body"></div>
          </div>
          <div class="dx-tooltip-appointment-item-content">
            <div class="dx-tooltip-appointment-item-content-subject">
              {{ data.appointmentData.text }}
            </div>
            <div class="dx-tooltip-date-content">
              <span class="dx-tooltip-date">
                {{
                  {
                    start: data.appointmentData.startDate,
                    end: data.appointmentData.endDate
                  } | trainingDate : translateService.currentLang
                }}
              </span>
              <span *ngIf="translateService.currentLang == 'en'">
                {{ data.appointmentData.startDate | date : "hh:mm a" }} -
                {{ data.appointmentData.endDate | date : "hh:mm a" }}
              </span>
              <span *ngIf="translateService.currentLang == 'de'">
                {{ data.appointmentData.startDate | date : "HH:mm" }} -
                {{ data.appointmentData.endDate | date : "HH:mm" }}
              </span>
            </div>
          </div>
        </a>
      </ng-container> -->
    </dx-scheduler>
  </div>
</app-page>
