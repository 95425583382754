import {
  Component,
  Input,
  Output,
  ViewChild,
  EventEmitter,
  ElementRef,
} from '@angular/core';
import { TableColumn } from '../../../models/table-column.model';
import { DxDataGridComponent } from 'devextreme-angular';
import { Router } from '@angular/router';
import { Column } from 'devextreme/ui/data_grid';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent {
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid!: DxDataGridComponent;

  @ViewChild('searchInputBox') set searchInputBoxContent(
    content: ElementRef<HTMLElement>
  ) {
    if (content) {
      this.searchBoxInput = content;
    }
  }
  searchBoxInput!: ElementRef<HTMLElement>;

  @ViewChild(DxDataGridComponent, { static: true })
  grid!: DxDataGridComponent;

  @Input()
  data: any[] = [];

  @Input()
  columns: TableColumn[] = [];

  @Input()
  showExport: boolean = false;

  @Input()
  showGrouping: boolean = false;

  @Input()
  showSelection: boolean = false;

  @Input()
  selectionMode: string = 'multiple';

  @Input()
  showSearch: boolean = false;

  @Input()
  searchLabel: string = '';

  @Input()
  searchPlacehoder: string = '';

  @Input()
  showActionButton: boolean = false;

  @Input()
  actionButtonIcon: string = '';

  @Input()
  actionButtonText: string = '';

  @Input()
  allowDataEditing: boolean = false;

  @Input()
  pageSize: number = 20;

  @Input()
  allowedPageSizes: Array<number> = [10, 20, 40];

  @Input()
  showPageSizeSelector: boolean = true;
  
  @Input()
  showPagination: boolean = true;

  @Output()
  actionFunction = new EventEmitter();

  @Output()
  onRowClick = new EventEmitter();

  @Output()
  onRowUpdate = new EventEmitter();

  @Output()
  onRowUpdateStarted = new EventEmitter();

  @Output()
  selectHandler = new EventEmitter();

  groupingValues: any;
  editingRowKey: any = null;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.groupingValues = [
      {
        value: null,
        text: `Default`,
      },
    ];

    this.columns.map((column) => {
      this.groupingValues.push({
        value: column.dataField,
        text: column.caption,
      });
    });
  }

  toggleGroupColumn(e: any) {
    this.grid.instance.clearGrouping();

    if (e.value) {
      this.grid.instance.columnOption(e.value, 'groupIndex', 0);
    }
  }

  calculateCellValueDefault(value: any) {
    const column = this as any;
    return column.defaultCalculateCellValue(value);
  }

  calculateFilterExpressionDefault(this: Column) {
    return this.defaultCalculateFilterExpression!.apply(
      this,
      arguments as unknown as [
        filterValue: any,
        selectedFilterOperation: string | null,
        target: string
      ]
    );
  }

  onTableSearch(event: any): void {
    this.grid.instance.searchByText(event.value);
    setTimeout(() => {
      // @ts-ignore
      this.searchBoxInput!.instance!.focus();
    }, 100);
  }

  onRowClickHandler(event: any) {
    if (this.onRowClick) {
      this.onRowClick.emit(event.data.id);
    }
  }

  onRowEditingStartHandler(e: any) {
    this.editingRowKey = e.key;
    if (this.onRowUpdateStarted) {
      this.onRowUpdateStarted.emit(this.editingRowKey);
    }
  }

  onRowUpdateHandler(event: any) {
    if (this.onRowUpdate) {
      this.onRowUpdate.emit(event);
    }
  }

  onRowUpdateCancelledHandler() {
    this.editingRowKey = null;
    if (this.onRowUpdateStarted) {
      this.onRowUpdateStarted.emit(null);
    }
  }

  onRowUpdateSavedHandler() {
    this.editingRowKey = null;
    if (this.onRowUpdateStarted) {
      this.onRowUpdateStarted.emit(null);
    }
  }

  onRowSelected(e: any) {
    this.selectHandler.emit(e.selectedRowKeys);
  }

  customizeBulletTooltip({ originalValue }: Record<string, string>) {
    return { text: `${parseInt(originalValue)}%` };
  }

  getBulletColor(value: number) {
    if (value >= 0 && value < 34) {
      return '#f53d3dcc';
    } else if (value >= 34 && value < 67) {
      return '#e8b34acc';
    } else if (value >= 67 && value < 100) {
      return '#aed959cc';
    } else if (value >= 100) {
      return '#66cc66cc';
    }
    return '#66cc66cc';
  }

  onExporting(e: any) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('IDD report');

    exportDataGrid({
      component: this.dataGrid.instance,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'idd-report.xlsx');
      });
    });
  }
}
