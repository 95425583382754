import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  HostListener
} from '@angular/core';
import { DxListComponent } from 'devextreme-angular';
import { OrganisationUnitTypeModel } from 'src/app/models/organisation-unit/organisation-unit-type.model';
import { OrganisationUnitModel } from 'src/app/models/organisation-unit/organisation-unit.model';
import { PersonSummaryModel } from 'src/app/models/person/person-summary.model';
import { PersonModel } from 'src/app/models/person/person.model';
import { TrainingInvitationSearchModel } from 'src/app/models/training/training-invitation-search.model';
import { LoadingService } from 'src/app/services/loading.service';
import { OrganisationUnitService } from 'src/app/services/organisation-unit.service';
import { TrainingMessageService } from 'src/app/services/training-message.service';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

@Component({
  selector: 'app-attendees-list',
  templateUrl: './attendees-list.component.html',
  styleUrl: './attendees-list.component.scss',
})
export class AttendeesListComponent implements OnInit {
  @ViewChild('list', { static: true })
  list!: DxListComponent;

  organisationUnits: OrganisationUnitModel[] = [];
  locations: OrganisationUnitModel[] = [];
  allPersons: PersonModel[] = [];
  selectedPersons: PersonModel[] = [];
  page: number = 0;

  selectedItemKeys: string[] = [];
  isAllSelected: boolean | null | undefined = false;
  isSearchLimit: boolean = false;

  formData = {
    locationFilter: [],
    organizationFilter: [],
    search: '',
  };

  @Output() onAdd = new EventEmitter();

  store!: CustomStore;
  dataSource!: DataSource;

  constructor(
    private organisationUnitService: OrganisationUnitService,
    private trainingInvitationService: TrainingMessageService,
    private loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    // this.store = new CustomStore({
    //   load: () => {
    //     const model: TrainingInvitationSearchModel = {
    //       page: this.page,
    //       searchText: this.formData.search,
    //       organisationUnitIdFilter: this.formData.organizationFilter,
    //     };
    //     this.isLoading = true;

    //     return new Promise((resolve) => {
    //       this.trainingInvitationService
    //         .searchPersonsForInvitation(model)
    //         .subscribe((result) => {
    //           this.page++;
    //           resolve({ data: result.persons, totalCount: result.total });

    //           setTimeout(() => {
    //             this.allPersons.forEach((person: PersonModel, idx: number) => {
    //               const selectedPerson = this.selectedPersons.find(
    //                 (selectedPerson: PersonModel) =>
    //                   selectedPerson.id == person.id
    //               );
    //               if (selectedPerson) {
    //                 this.list.instance.selectItem(idx);
    //               }
    //             });
    //             this.isLoading = false;
    //           });
    //         });
    //     });
    //   },
    // });

    // this.dataSource = new DataSource({
    //   store: this.store,
    //   pageSize: 20
    // });

    this.loadingService
      .load(this.organisationUnitService.getAllOrganisationUnits())
      .subscribe((organizations) => {
        this.organisationUnits = organizations.filter(
          (item) => item.type === OrganisationUnitTypeModel.Department
        );
        this.locations = organizations.filter(
          (item) => item.type === OrganisationUnitTypeModel.Location
        );
        this.doSearch(true);
      });
  }

  onLocationsSelected(e: any) {
    this.formData.locationFilter = e.component.getSelectedNodeKeys();
    this.doSearch(true);
  }

  onOrganizationSelected(e: any) {
    this.formData.organizationFilter = e.component.getSelectedNodeKeys();
    this.doSearch(true);
  }

  onSerachValueChanged(e: any) {
    this.doSearch(true);
  }

  doSearch(resetSearch: boolean): void {
    if (!resetSearch && this.isSearchLimit) {
      return;
    } else if (resetSearch) {
      this.isSearchLimit = false;
    }

    const page = resetSearch ? 0 : this.page + 1;
    const model: TrainingInvitationSearchModel = {
      page,
      searchText: this.formData.search,
      organisationUnitIdFilter: [...this.formData.locationFilter, ...this.formData.organizationFilter],
    };

    this.loadingService
      .load(this.trainingInvitationService.searchPersonsForInvitation(model))
      .subscribe((result) => {
        if (!result.persons.length) {
          this.isSearchLimit = true;
        }

        this.page = result.page;
        const data = result.persons.map((person: PersonModel) => {
          person.selected = false;
          return person;
        });
        this.allPersons = resetSearch
          ? data
          : this.allPersons.concat(data);

        this.setAlreadySelected();
        this.checkIfAllSelected();
      });
  }

  onScrolled(event: any) {
    const scrollTop = event.target.scrollTop;
    const scrollHeight = event.target.scrollHeight;
    const offsetHeight = event.target.offsetHeight;

    if (scrollTop + offsetHeight >= scrollHeight) {
      this.doSearch(false);
    }
  }

  setAlreadySelected() {
    this.allPersons.forEach((person: PersonModel) => {
      const existingPerson = this.selectedPersons.find((selectedPerson: PersonModel) => selectedPerson.id == person.id);

      if (existingPerson) {
        person.selected = true;
      }
    })
  }

  onPersonSelected(e: any, person: PersonModel) {
    if (person.selected) {
      this.selectedPersons.push(person);
    } else {
      this.selectedPersons = this.selectedPersons.filter(
        (selected: PersonModel) => selected.id !== person.id
      );
    }

    this.checkIfAllSelected();
    this.onAdd.emit(this.selectedPersons);
  }

  checkIfAllSelected() {
    const isAnyUnselected = this.allPersons.find((person: PersonModel) => !person.selected);
    const isAllSelected = !this.allPersons.find((person: PersonModel) => !person.selected);

    if (this.isAllSelected && isAnyUnselected) {
      this.isAllSelected = false;
    } else if (isAllSelected) {
      this.isAllSelected = true;
    }
  }

  onAllEmployeesClick(e: any) {
    if (!this.isAllSelected) {
      this.allPersons.forEach((person: PersonModel) => {
        person.selected = true;
      })
    } else {
      this.allPersons.forEach((person: PersonModel) => {
        person.selected = false;
      })
    }
  }

  onRemoveClicked(person?: PersonModel | PersonSummaryModel) {
    if (!person) {
      this.allPersons.forEach((person: PersonModel) => person.selected = false)
      return;
    }
    const index = this.selectedPersons.map((p) => p.id).indexOf(person.id);
    if (index < 0) {
      return;
    }
    this.selectedPersons.splice(index, 1);
  }


  onScroll(event: any) {
    const element = event.target;
    // Check if the user has scrolled to the bottom
    if (element.scrollHeight - element.scrollTop <= element.clientHeight + 1) {
      this.doSearch(false)
    }
  }

  // cancelFilters() {
  //   this.formData.locationFilter = [];
  //   this.formData.organizationFilter = [];
  //   this.formData.search = '';
  //   this.doSearch(true);
  // }

  // onAllEmployeesChanged(e: any) {
  //   if (this.isChangesBlocked) {
  //     this.isChangesBlocked = false;
  //     return;
  //   }
  //   if (e.value) {
  //     this.allPersons.forEach((person: PersonModel) => {
  //       person.selected = true;
  //     })
  //   } else {
  //     this.allPersons.forEach((person: PersonModel) => {
  //       person.selected = false;
  //     })
  //   }
  //   this.onAdd.emit(this.selectedPersons);
  // }
}
