import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {Configuration} from "../../assets/Configuration";
import {HttpClient} from "@angular/common/http";
import {TrainingSummaryModel} from "../models/training/training-summary.model";
import {TrainingFullModel} from "../models/training/training-full.model";
import {TrainingCreationAndUpdateModel} from "../models/training/training-creation-and-update.model";
import {EventMemberModel} from "../models/event-member.model";
import {RawFileModel} from "../models/raw-file-model";
import {DownloadService} from "./download.service";
import {TrainingCancelModel} from "../models/training/training-cancel.model";
import {TrainingCompleteModel} from "../models/training/training-complete.model";
import {TrainingFeedback} from '../models/training/training-feedback.model';
import {TrainingMeetingAttendeePersonModel} from "../models/training/training-meeting-attendee-person.model";
import { TrainingMemberModel } from '../models/training/training-member.model';

@Injectable({
    providedIn: 'root'
})
export class TrainingService {

    constructor(private httpClient: HttpClient, private downloadService: DownloadService) {
    }

    public getAllTrainings(): Observable<TrainingSummaryModel[]> {
        return this.httpClient.get<TrainingSummaryModel[]>(Configuration.getApiBaseUrl() + "/training/");
    }

    public getTrainingsByStatusAndYear(status: number, year: number): Observable<TrainingSummaryModel[]> {
        return this.httpClient.get<TrainingSummaryModel[]>(Configuration.getApiBaseUrl() + `/training/by-status/${status}/by-year/${year}`);
    }

    public getTraining(id: string): Observable<TrainingFullModel> {
        return this.httpClient.get<TrainingFullModel>(Configuration.getApiBaseUrl() + "/training/" + id);
    }

    public addTraining(model: TrainingCreationAndUpdateModel): Observable<TrainingFullModel> {
        return this.httpClient.post<TrainingFullModel>(Configuration.getApiBaseUrl() + "/training/", model);
    }

    public updateTraining(id: string, model: TrainingCreationAndUpdateModel): Observable<TrainingFullModel> {
        return this.httpClient.post<TrainingFullModel>(Configuration.getApiBaseUrl() + "/training/" + id, model);
    }

    public getEventMembers(id: string): Observable<EventMemberModel[]> {
        return this.httpClient.get<EventMemberModel[]>(Configuration.getApiBaseUrl() + "/training/" + id + "/members");
    }

    public getIcs(id: string): Observable<RawFileModel> {
        return this.httpClient.get<RawFileModel>(Configuration.getApiBaseUrl() + "/training/" + id + "/ics");
    }

    public deleteTraining(id: string): Observable<void> {
        return this.httpClient.delete<void>(Configuration.getApiBaseUrl() + "/training/" + id);
    }

    getMemberListPdf(id: string): Observable<RawFileModel> {
        return this.httpClient.get<RawFileModel>(Configuration.getApiBaseUrl() + "/training/" + id + "/member-list-pdf");
    }

    public cancelTraining(id: string, trainingCancelModel: TrainingCancelModel): Observable<void> {
        return this.httpClient.post<void>(Configuration.getApiBaseUrl() + "/training/" + id + "/cancel", trainingCancelModel);
    }

    public completeTraining(id: string, trainingCompleteModel: TrainingCompleteModel): Observable<void> {
        return this.httpClient.post<void>(Configuration.getApiBaseUrl() + "/training/" + id + "/complete", trainingCompleteModel);
    }

    public acceptTraining(id: string): Observable<void> {
        return this.httpClient.post<void>(Configuration.getApiBaseUrl() + "/training/" + id + "/accept", null);
    }

    public declineTraining(id: string): Observable<void> {
        return this.httpClient.post<void>(Configuration.getApiBaseUrl() + "/training/" + id + "/decline", null);
    }

    public getTrainingFeedback(id: string): Observable<TrainingFeedback> {
        return this.httpClient.get<TrainingFeedback>(Configuration.getApiBaseUrl() + "/SurveyResult/" + id);
    }

    public getTrainingMeetingAttendees(id: string): Observable<TrainingMeetingAttendeePersonModel[]> {
        return this.httpClient.get<TrainingMeetingAttendeePersonModel[]>(Configuration.getApiBaseUrl() + "/training/" + id + "/meeting-attendees");
    }

    public getTrainingMemberStatus(id: string): Observable<TrainingMemberModel> {
        return this.httpClient.get<TrainingMemberModel>(Configuration.getApiBaseUrl() + "/training/" + id + "/member");
    }

    public approveMemberRequest(id: string, personId: string): Observable<void> {
        return this.httpClient.post<void>(Configuration.getApiBaseUrl() + "/training/" + id + "/approve-request/" + personId, null);
    }

    public declineMemberRequest(id: string, personId: string): Observable<void> {
        return this.httpClient.post<void>(Configuration.getApiBaseUrl() + "/training/" + id + "/decline-request/" + personId, null);
    }
}
