import {Injectable} from '@angular/core';
import {DownloadService} from "./download.service";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Configuration} from "../../assets/Configuration";
import { AddExternalCertificateModel } from '../models/person/add-external-certificate.model';

@Injectable({
  providedIn: 'root'
})
export class CompletedEventMemberService {

  constructor(private downloadService: DownloadService, private httpClient: HttpClient) {
  }

  public downloadCertificate(id: string): void {
    const url = Configuration.getApiBaseUrl() + '/completedeventmember/' + id + '/certificate';
    this.downloadService.download(url);
  }

  public deleteExternalEvent(id: string): Observable<void> {
    return this.httpClient.delete<void>(Configuration.getApiBaseUrl() +  '/completedeventmember/' + id);
  }

  public updateCertificate(id: string, model: AddExternalCertificateModel): Observable<void> {
    return this.httpClient.post<void>(Configuration.getApiBaseUrl() +  '/completedevent/' + id + '/external-certificate', model);
  }

  public deleteCertificate(id: string): Observable<void> {
    return this.httpClient.delete<void>(Configuration.getApiBaseUrl() +  '/completedevent/' + id + '/external-certificate');
  }

  public deleteCertificateFile(id: string): Observable<void> {
    return this.httpClient.delete<void>(Configuration.getApiBaseUrl() +  '/completedevent/' + id + '/file');
  }
}
