import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
  @Input() height: string | number = 300;
  @Input() showModal = false;
  @Input() options: ConfirmationDialogData | null = null;

  @Output() showModalChange = new EventEmitter<boolean>();
  @Output() submit = new EventEmitter();

  dismiss = () => {
    this.showModal = false;
    this.showModalChange.emit(this.showModal);
  };

  onSubmit = () => {
    this.submit.emit();
  };
}

export interface ConfirmationDialogData {
  submitButtonText?: string;
  message?: string;
  hideUndo?: boolean;
  dismissButtonText?: string;
}
