import { Injectable } from '@angular/core';
import {HttpClient, HttpEvent, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {OrganisationUnitModel} from "../models/organisation-unit/organisation-unit.model";
import {Configuration} from "../../assets/Configuration";
import {map} from "rxjs/operators";
import {FolderCreationModel} from "../models/folder-creation.model";
import {FileModel} from "../models/file.model";

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor(private httpClient: HttpClient) {
  }

  public createFolder(folderCreationModel: FolderCreationModel): Observable<FileModel> {
    return this.httpClient.post<FileModel>(Configuration.getApiBaseUrl() + "/fileuploader/create-folder", folderCreationModel);
  }

  public uploadToFolder(folderId: string, fullPath: string, blob: Blob): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', blob, fullPath);
    const request = new HttpRequest('POST', Configuration.getApiBaseUrl() + "/fileuploader/upload-to-folder?folderId=" + folderId + "&path=abc123", formData, {reportProgress: true});
    return this.httpClient.request<any>(request);
  }

  public uploadFile(file: File, uploadStatusIdentifier: string): Observable<HttpEvent<FileModel>> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    const request = new HttpRequest('POST', Configuration.getApiBaseUrl() + "/fileuploader/upload-file?uploadStatusIdentifier=" + uploadStatusIdentifier, formData, {reportProgress: true});
    return this.httpClient.request<any>(request);
  }

}
