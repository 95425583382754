<dx-data-grid
  #dataGrid
  [dataSource]="data"
  keyExpr="id"
  [showBorders]="false"
  [showColumnLines]="false"
  [showRowLines]="true"
  [hoverStateEnabled]="true"
  (onSelectionChanged)="onRowSelected($event)"
  (onRowClick)="onRowClickHandler($event)"
  (onRowUpdated)="onRowUpdateHandler($event)"
  (onEditCanceled)="onRowUpdateCancelledHandler()"
  (onEditingStart)="onRowEditingStartHandler($event)"
  (onSaving)="onRowUpdateSavedHandler()"
  [columnAutoWidth]="true"
  [allowColumnResizing]="true"
  [columnResizingMode]="'nextColumn'"
  (onExporting)="onExporting($event)"
  [paging]="{ enabled: showPagination }"
  [scrolling]="{
    mode: 'standard',
    showScrollbar: 'always',
    useNative: false,
    scrollByThumb: true,
    scrollByContent: true
  }"
>
  <dxo-header-filter [visible]="true"></dxo-header-filter>
  <dxo-toolbar>
    <dxi-item *ngIf="showGrouping" location="before" width="100%">
      <div *dxTemplate>
        <dx-select-box
          class="table-grouping"
          width="225"
          [items]="groupingValues"
          [inputAttr]="{ 'aria-label': 'Value' }"
          displayExpr="text"
          valueExpr="value"
          labelMode="floating"
          stylingMode="outlined"
          placeholder=""
          [label]="'COMMON.GROUP-BY' | translate"
          (onValueChanged)="toggleGroupColumn($event)"
        >
        </dx-select-box>
      </div>
    </dxi-item>
    <dxi-item *ngIf="showSearch" location="after">
      <div *dxTemplate>
        <dx-text-box
          #searchInputBox
          icon="search"
          width="100%"
          [label]="searchPlacehoder"
          stylingMode="filled"
          labelMode="floating"
          stylingMode="outlined"
          value=""
          valueChangeEvent="input"
          (onValueChanged)="onTableSearch($event)"
        >
          <dxi-button
            name="search"
            type="danger"
            location="before"
            [options]="{ icon: 'search', type: 'default', disabled: true }"
          ></dxi-button>
        </dx-text-box>
      </div>
    </dxi-item>
    <dxi-item *ngIf="showActionButton" location="after">
      <div *dxTemplate>
        <dx-button
          class="cta-btn"
          type="default"
          [icon]="actionButtonIcon"
          [text]="actionButtonText!"
          (click)="actionFunction.emit()"
        ></dx-button>
      </div>
    </dxi-item>
    <dxi-item *ngIf="showExport" location="after">
      <div *dxTemplate>
        <dx-button
          class="cta-btn"
          type="normal"
          (click)="onExporting($event)"
          >{{ "COMMON.EXPORT-TO-EXCEL" | translate }}</dx-button
        >
      </div>
    </dxi-item>
  </dxo-toolbar>
  <dxo-editing mode="row" [allowUpdating]="allowDataEditing" [useIcons]="true">
  </dxo-editing>
  <dxo-paging [pageSize]="pageSize"> </dxo-paging>
  <dxo-pager
    [allowedPageSizes]="allowedPageSizes"
    displayMode="full"
    [visible]="showPagination"
    [showInfo]="true"
    [showPageSizeSelector]="showPageSizeSelector"
    [showNavigationButtons]="true"
  >
  </dxo-pager>
  <dxo-selection
    *ngIf="showSelection"
    [mode]="selectionMode"
    showCheckBoxesMode="always"
  ></dxo-selection>
  <dxi-column
    *ngFor="let column of columns; let i = index"
    [cellTemplate]="null"
    [cssClass]="'table-cell' + column.cssClass ? ' ' + column.cssClass : ''"
    [caption]="column.caption"
    [name]="column.name || null"
    [dataField]="column.dataField"
    [cellTemplate]="column.cellTemplate"
    [allowFiltering]="column.disableFiltering ? false : true"
    [calculateFilterExpression]="
      column.filterExpression
        ? column.filterExpression
        : calculateFilterExpressionDefault
    "
    [calculateCellValue]="
      column.cellValue ? column.cellValue : calculateCellValueDefault
    "
    [dataType]="column.cellType"
    [calculateDisplayValue]="column.calculateDisplayValue"
    [calculateSortValue]="column.calculateSortValue"
    [allowEditing]="column.allowEditing ? true : false"
  >
    <dxo-header-filter
      *ngIf="column.headerFilter"
      [dataSource]="column.headerFilter"
    ></dxo-header-filter>
    <ng-container *ngIf="column.actions">
      <div *dxTemplate="let data of column.cellTemplate" class="actions-cell">
        <div *ngFor="let action of column.actions">
          <dx-button
            type="default"
            [disabled]="
              action.isDisabledAction
                ? action.isDisabledAction(data.value)
                : false
            "
            *ngIf="action.isShowAction(data.value) && action.icon"
            [icon]="action.icon || ''"
            (click)="action.onClick(data.value, $event)"
            [class]="action.class || ''"
          >
          </dx-button>
          <span *ngIf="action.isShowAction(data.value) && action.text">
            {{ action.text }}
          </span>
        </div>
      </div>
    </ng-container>
    <ng-container
      *ngIf="column.cellTemplate && column.cellTemplate == 'progressBullet'"
    >
      <div *dxTemplate="let data of column.cellTemplate">
        <dx-bullet
          [color]="getBulletColor(data.value)"
          [showTarget]="false"
          [showZeroLevel]="true"
          [startScaleValue]="0"
          [endScaleValue]="100"
          [value]="data.value"
          [tooltip]="{ enabled: false }"
        >
          <dxo-size [width]="150" [height]="35"></dxo-size>
          <dxo-margin [top]="5" [bottom]="0" [left]="5"></dxo-margin>
        </dx-bullet>
      </div>
    </ng-container>
    <ng-container *ngIf="column.cellTemplate && column.cellTemplate == 'link'">
      <div *dxTemplate="let data of column.cellTemplate">
        <a [routerLink]="data.data.link">{{ data.value }}</a>
      </div>
    </ng-container>
  </dxi-column>
  <!-- <dxo-export [enabled]="showExport" [allowExportSelectedData]="false"></dxo-export> -->
</dx-data-grid>
