import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Configuration } from '../../assets/Configuration';
import { ReportIddModel } from '../models/reporting/report-idd.model';
import { ReportComplianceModel } from '../models/reporting/report-compliance.model';

@Injectable({
  providedIn: 'root',
})
export class ReportingService {
  constructor(private httpClient: HttpClient) {}

  public getIddReport(year: number): Observable<ReportIddModel[]> {
    return this.httpClient.get<ReportIddModel[]>(
      Configuration.getApiBaseUrl() + `/Reporting/idd-report?year=${year}`
    );
  }

  public getComplianceReport(year: number): Observable<ReportComplianceModel[]> {
    return this.httpClient.get<ReportComplianceModel[]>(
      Configuration.getApiBaseUrl() + `/Reporting/compliance-report?year=${year}`
    );
  }
}
