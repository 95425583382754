import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingService } from '../../../services/loading.service';
import { TranslateService } from '@ngx-translate/core';
import { ReportingService } from 'src/app/services/reporting.service';
import { ReportIddModel } from 'src/app/models/reporting/report-idd.model';
import { TableColumn } from 'src/app/models/table-column.model';
import { PersonSummaryWithIddModel } from 'src/app/models/person/person-summary-with-idd.model';
import { TimeService } from 'src/app/services/time.service';
import { PersonSummaryModel } from 'src/app/models/person/person-summary.model';

@Component({
  selector: 'app-reporting-compliance',
  templateUrl: './reporting-compliance.component.html',
  styleUrls: ['./reporting-compliance.component.scss'],
})
export class ReportingComplianceComponent {
  reportData: ReportIddModel[] = [];
  yearFilter: number | null = null;

  yearsDropdownDataSource: any[] = [
    {
      id: new Date().getFullYear(),
      name: new Date().getFullYear(),
    },
    {
      id: new Date().getFullYear() - 1,
      name: new Date().getFullYear() - 1,
    },
    {
      id: new Date().getFullYear() - 2,
      name: new Date().getFullYear() - 2,
    },
    {
      id: new Date().getFullYear() - 3,
      name: new Date().getFullYear() - 3,
    },
    {
      id: new Date().getFullYear() - 4,
      name: new Date().getFullYear() - 4,
    },
  ];

  reportColumns: TableColumn[] = [
    {
      caption: this.translateService.instant('COMMON.FIELDS.LAST-NAME'),
      dataField: 'lastName',
      dataType: 'string',
    },
    {
      caption: this.translateService.instant('COMMON.FIELDS.FIRST-NAME'),
      dataField: 'firstName',
      dataType: 'string',
    },
    {
      caption: this.translateService.instant('COMMON.FIELDS.LOCATION'),
      dataField: 'location.name',
      dataType: 'string',
    },
    {
      caption: this.translateService.instant('COMMON.FIELDS.YEAR'),
      dataField: 'year',
      dataType: 'string',
      calculateDisplayValue: (data: any): string => {
        return this.yearFilter!.toString();
      },
      cssClass: 'width-100',
    },
    {
      caption: this.translateService.instant('COMMON.FIELDS.TRAINING-GROUPS'),
      dataField: 'trainingGroups',
      dataType: 'string',
      headerFilter: (data: any) => {
        data.dataSource.postProcess = (results: any) => {
          const trainingGroupNames = results.reduce(
            (a: any, b: any) => a.concat(b.value),
            []
          );
          const parsedTrainingGroupNames: string[] = [];
          trainingGroupNames.forEach((group: string) => {
            if (!group || !group.includes(', ')) {
              parsedTrainingGroupNames.push(group);
            } else if (group.includes(', ')) {
              const groups = group.split(', ');
              parsedTrainingGroupNames.push(...groups);
            }
          });
          const uniqueTrainingGroupNames = [
            ...new Set(parsedTrainingGroupNames),
          ];

          results.splice(0, results.length);
          uniqueTrainingGroupNames.forEach((tgName) => {
            if (tgName) {
              results.push({
                text: tgName,
                value: tgName,
              });
            } else {
              results.push({
                text: '(Blanks)',
                value: null,
              });
            }
          });
          return results;
        };
      },
      filterExpression: (value: any) => {
        function getTrainingGroupsFilterString(rowData: PersonSummaryModel) {
          const groups = rowData.trainingGroups.map((tg) => tg.name);
          return !!groups.join(',') ? groups.join(',') : 'empty';
        }

        return [[getTrainingGroupsFilterString, 'contains', value || 'empty']];
      },
      cellValue: (data: PersonSummaryModel | any): string => {
        return data.trainingGroups.map((tg: any) => tg.name).join(', ');
      },
    },
  ];

  constructor(
    private router: Router,
    private loadingService: LoadingService,
    private reportingService: ReportingService,
    public translateService: TranslateService
  ) {
    this.yearFilter = this.yearsDropdownDataSource[0].id;
    this.loadReportingData();
  }

  loadReportingData(): void {
    this.loadingService
      .load(this.reportingService.getComplianceReport(2025))
      .subscribe((reportData) => {
        reportData[0].iddTimeMinutes = 0;
        this.reportData = reportData;
      });
  }

  navigateBack(): void {
    this.router.navigate(['/dashboard']);
  }

  onYearFilterChanged(event: any) {
    this.yearFilter = event.value;
    this.loadReportingData();
  }
}
