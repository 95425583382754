import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {IntegrationModel, WbdModel} from "../../models/integration/integration.model";
import {TrainingLocationTypeModel} from "../../models/training/training-location-type.model";
import {Configuration} from "../../../assets/Configuration";

@Injectable({
    providedIn: 'root'
})
export class IntegrationsService {

    public static webexRedirectUrl = window.location.origin + '/integrations/webex/oauth';
    public static goToWebinarRedirectUrl = window.location.origin + '/integrations/gotowebinar/oauth';
    public static teamRedirectUrl = window.location.origin + '/integrations/msteams/oauth';
    public static goToZoomRedirectUrl = window.location.origin + '/integrations/zoom/oauth';

    constructor(private httpClient: HttpClient) {
    }

    public getIntegrations(): Observable<IntegrationModel[]> {
        return this.httpClient.get<IntegrationModel[]>(Configuration.getApiBaseUrl() + '/integration');
    }

    public getOAuthUrl(trainingLocationType: TrainingLocationTypeModel, redirectUrl: string): Observable<string> {
        debugger;
        return this.httpClient.get(Configuration.getApiBaseUrl() + '/Integration/' + trainingLocationType + '/oauth-url?redirectUrl=' + redirectUrl, {responseType: 'text'});
    }
    public getOAuthUrlTeam(trainingLocationType: TrainingLocationTypeModel, redirectUrl: string): Observable<string> {
        return this.httpClient.get(Configuration.getApiBaseUrl() + '/Integration/' + trainingLocationType + '/oauth-url?redirectUrl=' + redirectUrl, {responseType: 'text'});
    }

    public disconnect(trainingLocationType: TrainingLocationTypeModel): Observable<void> {
        return this.httpClient.post<void>(Configuration.getApiBaseUrl() + '/Integration/' + trainingLocationType + '/disconnect', null);
    }

    public integrate(trainingLocationType: TrainingLocationTypeModel, code: string, redirectUrl: string): Observable<void> {
        return this.httpClient.post<void>(Configuration.getApiBaseUrl() + '/Integration/' + trainingLocationType + '/integrate?code=' + code + '&redirectUrl=' + redirectUrl, null);
    }

    public getWbd(): Observable<boolean> {
        return this.httpClient.get<boolean>(Configuration.getApiBaseUrl() + '/Wbd/configured');
    }
    
    public configureWbd(wbdModel: WbdModel): Observable<WbdModel> {
        return this.httpClient.post<WbdModel>(Configuration.getApiBaseUrl() + '/Wbd', wbdModel);
    }

    public disconnectWbd(): Observable<void> {
        return this.httpClient.delete<void>(Configuration.getApiBaseUrl() + '/Wbd');
    }

    // private getRedirectUrl(): string {
    //   return window.location.origin + '/integrations/webex/oauth';
    // return window.location.origin + '/integrations/gotowebinar/oauth';
    // }
}
