<div class="trigger-wrapper" (click)="toggleShowModal()">
  <ng-content></ng-content>
</div>

<dx-popup
  [width]="980"
  [height]="'auto'"
  [showTitle]="true"
  [title]="'MY-TRAININGS.TRAINING-INVITE.SELECT-ATTENDEES' | translate"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [(visible)]="showModal"
  [showCloseButton]="true"
  class="modal"
  [classList]="'modal'"
  [copyRootClassesToWrapper]="false"
>
  <div *dxTemplate="let data of 'content'">
    <app-attendees-list (onAdd)="selectionChange($event)"></app-attendees-list>
  </div>

  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="before"
    [options]="{
      stylingMode: 'outlined',
      text: 'COMMON.CLOSE' | translate,
      onClick: toggle
    }"
  >
  </dxi-toolbar-item>
  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="after"
    [options]="{
      stylingMode: 'contained',
      text: 'COMMON.ADD' | translate,
      onClick: onSubmitClick
    }"
  ></dxi-toolbar-item>
</dx-popup>
