<dx-drawer
  *ngIf="user && !isAnonymousRoute"
  class="drawer"
  position="before"
  [closeOnOutsideClick]="shaderEnabled"
  [openedStateMode]="menuMode"
  [revealMode]="menuRevealMode"
  [minSize]="minMenuSize"
  [shading]="shaderEnabled"
  [(opened)]="menuOpened"
  template="drawer-template"
>
  <app-side-nav
    [selectedItem]="selectedRoute"
    [userRole]="userRole"
    [userLocation]="userLocation"
    class="dx-swatch-additional"
    *dxTemplate="let dataMenu of 'drawer-template'"
    (selectedItemChanged)="navigationChanged($event)"
  >
    <img *ngIf="logoUrl" class="logo" [src]="logoUrl" />
  </app-side-nav>
  <div class="container">
    <app-header
      class="layout-header"
      [user]="user"
      [menuToggleEnabled]="shaderEnabled"
      (menuToggle)="menuOpened = !menuOpened"
    ></app-header>
    <router-outlet *ngIf="!isIframe"></router-outlet>
    <app-download *ngIf="!isIframe"></app-download>
  </div>
</dx-drawer>

<ng-container *ngIf="isAnonymousRoute">
  <router-outlet></router-outlet>
</ng-container>