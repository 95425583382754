<dx-popup
  [width]="520"
  [height]="'auto'"
  [showTitle]="true"
  [title]="'USER-NAV.CHANGE-PASSWORD' | translate"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [showCloseButton]="true"
  class="modal"
  [classList]="'modal'"
  [copyRootClassesToWrapper]="false"
  [visible]="showModal"
  (onHidden)="closeModal()"
>
  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="after"
    [options]="{
      stylingMode: 'contained',
      text: 'USER-NAV.CHANGE-PASSWORD' | translate,
      onClick: submit
    }"
  ></dxi-toolbar-item>
  <div *dxTemplate="let data of 'content'">
    <dx-form #form [formData]="formData" labelMode="floating">
      <dxi-item
        dataField="password"
        editorType="dxTextBox"
        [editorOptions]="{ mode: 'password' }"
      >
        <dxo-label
          [text]="'USER-NAV.CHANGE-PASSWORD-MODAL.NEW-PASSWORD' | translate"
        ></dxo-label>
        <dxi-validation-rule
          type="required"
          [message]="
            'USER-NAV.CHANGE-PASSWORD-MODAL.PASSWORD-IS-REQUIRED' | translate
          "
        ></dxi-validation-rule>
      </dxi-item>
      <dxi-item
        dataField="repeatPassword"
        editorType="dxTextBox"
        [editorOptions]="{ mode: 'password' }"
      >
        <dxo-label
          [text]="'USER-NAV.CHANGE-PASSWORD-MODAL.REPEAT-PASSWORD' | translate"
        ></dxo-label>
        <dxi-validation-rule type="required"></dxi-validation-rule>
        <dxi-validation-rule
          type="custom"
          [message]="
            'USER-NAV.CHANGE-PASSWORD-MODAL.PASSWORDS-DO-NOT-MATCH' | translate
          "
          [validationCallback]="validation"
        >
        </dxi-validation-rule>
      </dxi-item>
    </dx-form>
  </div>
</dx-popup>
