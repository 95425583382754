import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingService } from '../../../services/loading.service';
import { ToastrService } from 'ngx-toastr';
import { TrainingLocationTypeModel } from '../../../models/training/training-location-type.model';
import { IntegrationsService } from '../../../services/integration/integrations.service';
@Component({
  selector: 'app-zoom',
  standalone: true,
  imports: [],
  templateUrl: './zoom.component.html',
  styleUrl: './zoom.component.css'
})
export class ZoomComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService,
    private router: Router,
    private toastrService: ToastrService,
    private integrationsService: IntegrationsService
  ) {}

  ngOnInit(): void {
    const code = this.activatedRoute.snapshot.queryParams['code'];
    const obs = this.integrationsService.integrate(
      TrainingLocationTypeModel.Zoom,
      code,
      IntegrationsService.goToZoomRedirectUrl
    );
    this.loadingService.load(obs).subscribe(() => {
      this.toastrService.success('Zoom Integration erfolgreich');
      this.router.navigate(['integrations']);
    });
  }
}
