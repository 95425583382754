import { Component, Input, ViewChild } from '@angular/core';
import { GoogleMapsService } from '../../../services/google-maps.service';
import { LocationModel } from '../../../models/location/location.model';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-google-maps',
  templateUrl: './google-maps.component.html',
  styleUrls: ['./google-maps.component.scss'], 
})
export class GoogleMapsComponent {
  apiKey!: string;
  googleMapUrl!: any;
  zoom!: number;
  center!: any;
  markers: any[] = [];
  private _location: any | null = null;

  get location(): any | null {
    return this._location;
  }

  @Input() set location(value: any | null) {
    this._location = value;
    if (value && value.latitude && value.longitude) {
      const mapUrl = `https://www.google.com/maps/embed/v1/place?key=${this.apiKey}&q=${value.latitude},${value.longitude}`;
      this.googleMapUrl = this.sanitizer.bypassSecurityTrustResourceUrl(mapUrl);
      const coordinates = { lat: value.latitude, lng: value.longitude };
      this.center = coordinates;
      this.markers = [{ location: coordinates }];
      this.zoom = 15;
    } else {
      this.resetCenterAndZoom();
    }
  }

  constructor(googleMapsService: GoogleMapsService,private sanitizer: DomSanitizer) {
    this.resetCenterAndZoom();
    this.apiKey = googleMapsService.getApiKey();
  }

  private resetCenterAndZoom(): void {
    this.center = { lat: 51.11887401909386, lng: 10.274759055965852 };
    this.zoom = 5;
  }
}
