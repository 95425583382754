<div>
  <app-page>
    <div class="top-cta">
      <dx-button
        [text]="'COMMON.BACK' | translate"
        icon="back"
        type="default"
        class="integration-btn"
        (click)="onBackClick()"
      >
      </dx-button>

      <dx-button
        [text]="'COMMON.COMPLETE' | translate"
        (click)="onCompleteClick()"
      >
      </dx-button>
    </div>
    <app-page-title
      [title]="'TRAINING.COMPLETE.SELECT-ATTENDEES' | translate"
    ></app-page-title>
    <app-panel [title]="'TRAINING.COMPLETE.ATTENDEES-LIST' | translate">
      <dx-form #form [formData]="formData" labelLocation="top">
        <dxi-item>
          <dxo-label [visible]="false"></dxo-label>
          <div *dxTemplate class="file-uploader-wrapper">
            <dx-file-uploader
              [visible]="false"
              dialogTrigger="#fileTrigger"
              accept="*/*"
              [multiple]="false"
              uploadMode="useForm"
              [showFileList]="true"
              (valueChange)="onSelectedFileChange($event)"
            >
            </dx-file-uploader>
            <dx-button
              id="fileTrigger"
              [text]="'COMMON.SELECT-FILE' | translate"
              type="default"
              class="integration-btn"
            ></dx-button>
            <span *ngIf="!formData.attachment && isAttachmentMandatory" class="no-file-text">
              {{'TRAINING.COMPLETE.UPLOAD-ATTENDANCE-LIST-INFO'| translate}}
            </span>
            <span *ngIf="!formData.attachment && !isAttachmentMandatory" class="no-file-text">
              {{'TRAINING.COMPLETE.UPLOAD-ATTENDANCE-LIST-INFO-OPTIONAL'| translate}}
            </span>
            <span class="file-name" *ngIf="formData.attachment">
              {{ formData.attachment.name }}
            </span>
          </div>
        </dxi-item>
      </dx-form>
      <app-attendee-list #attendeeList [trainingId]="trainingId"></app-attendee-list>
    </app-panel>
  </app-page>
</div>
