import { Component, ViewChild } from '@angular/core';
import { forkJoin, shareReplay } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { DxSelectBoxTypes } from 'devextreme-angular/ui/select-box';
import { TenantModel } from '../../models/settings/tenant.model';
import { QualificationTargetTypeModel } from '../../models/settings/qualification-target-type.model';
import { LoadingService } from '../../services/loading.service';
import { GeneralSettingsService } from '../../services/general-settings.service';
import { PersonService } from '../../services/person.service';
import { TimeService } from '../../services/time.service';
import { TranslateService } from '@ngx-translate/core';
import { RoleService } from '../../services/role.service';
import { UnregisteredRoleModel } from '../../models/settings/unregistered-role.model';
import { UserService } from '../../services/user.service';
import { DxDropDownBoxComponent } from 'devextreme-angular';
import {DxTagBoxTypes} from "devextreme-angular/ui/tag-box";
import {TenantUpdateModel} from "../../models/settings/tenant-update.model";

interface SelectOption {
  id: string;
  name: string;
  selected?: boolean;
}

@Component({
  selector: 'app-external-contacts-form',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.scss'],
})
export class GeneralSettingsComponent {
  @ViewChild(DxDropDownBoxComponent, { static: false }) iddDropDown!: DxDropDownBoxComponent;
  isDataLoaded: boolean = false;
  employees!: SelectOption[];
  qualificationTargetTypeOptions!: DxSelectBoxTypes.Properties;
  amount: { hours: number; minutes: number } = {
    hours: 0,
    minutes: 0,
  };
  tenantUpdateModel: TenantUpdateModel = {
    qualificationTargetIddTime: 0,
    qualificationTargetType: QualificationTargetTypeModel.ByEmployee,
    iddResponsiblePersonId: '',
    createEmployeeAccounts: false,
    createManagerAccounts: false,
    createTrainerAccounts: false,
    createSuperAdminAccounts: false,
    sendTrainingProgressMail: false,
    domains: []
  };

  missingEmployeesData: UnregisteredRoleModel | null = null;
  missingManagersData: UnregisteredRoleModel | null = null;
  missingTrainersData: UnregisteredRoleModel | null = null;
  missingSuperAdminsData: UnregisteredRoleModel | null = null;

  constructor(
    private loadingService: LoadingService,
    private settingsService: GeneralSettingsService,
    private personService: PersonService,
    private roleService: RoleService,
    private userService: UserService,
    private toastrService: ToastrService,
    private translateService: TranslateService
  ) {
    const settingsData = this.loadingService
      .load(
        forkJoin({
          settings: this.settingsService.getTenant(),
          employees: this.personService.getAllPersons(),
          roles: this.roleService.getAllRoles(),
          domains: this.settingsService.getTenantDomains()
        })
      )
      .pipe(shareReplay(1));

    settingsData.subscribe(({ settings, employees, roles, domains }) => {
      this.employees = employees.map((employee) => ({
        id: employee.id,
        name: `${employee.firstName} ${employee.lastName}`,
      }));

      settings.unregisteredPersons!.forEach((personType) => {
        const role = roles.find((role) => role.id == personType.roleId);

        if (role!.name === 'Manager') {
          this.missingManagersData = personType;
        } else if (role!.name === 'Employee') {
          this.missingEmployeesData = personType;
        } else if (role!.name === 'Trainer') {
          this.missingTrainersData = personType;
        } else if (role!.name === 'SuperAdmin') {
          this.missingSuperAdminsData = personType;
        }
      });

      this.patchForm(settings, domains);
      this.setDropdownOptions();
      this.isDataLoaded = true;
    });
  }

  registerMissingAccounts(roleId: string) {
    this.loadingService
      .load(this.userService.registerMissingAccounts(roleId))
      .subscribe(() => {
        this.toastrService.success(
          this.translateService.instant('SETTINGS.CREATE-ACCOUNTS-SUCCESS')
        );
        window.location.reload();
      });
  }

  setDropdownOptions() {
    const defaultSelectOptions = {
      showClearButton: true,
      stylingMode: 'outlined',
      displayExpr: 'name',
      valueExpr: 'id',
      searchEnabled: true,
    } as DxSelectBoxTypes.Properties;

    this.qualificationTargetTypeOptions = {
      ...defaultSelectOptions,
      searchEnabled: false,
      dataSource: [
        {
          id: 0,
          name: this.translateService.instant(
            'COMMON.FIELDS.DEFINITION-TYPES.BY-EMPLOYEE'
          ),
        },
      ],
      placeholder: this.translateService.instant('COMMON.FIELDS.DEFINITION'),
    };
  }

  handleTimePicker(value: { hours: number; minutes: number }) {
    this.amount.hours = value.hours;
    this.amount.minutes = value.minutes;
    this.tenantUpdateModel.qualificationTargetIddTime =
      this.amount.hours * 60 + this.amount.minutes;
  }

  onIddResponsibleSelected(e: any) {
    if (e.node.key) {
      this.tenantUpdateModel.iddResponsiblePersonId = e.node.key;
    }
  }

  onFormSubmit(): void {
    const result = this.settingsService.updateSettings(this.tenantUpdateModel);
    this.loadingService.load(result).subscribe(() => {
      this.toastrService.success('Settings successfully updated!');
    });
  }

  patchForm(tenant: TenantModel, domains: string[]) {
    this.tenantUpdateModel.qualificationTargetIddTime =
      tenant.qualificationTargetIddTime;
    this.tenantUpdateModel.qualificationTargetType =
      tenant.qualificationTargetType;
    this.tenantUpdateModel.iddResponsiblePersonId = tenant.iddResponsiblePersonId;
    this.tenantUpdateModel.createEmployeeAccounts = tenant.createEmployeeAccounts;
    this.tenantUpdateModel.createManagerAccounts = tenant.createManagerAccounts;
    this.tenantUpdateModel.createTrainerAccounts = tenant.createTrainerAccounts;
    this.tenantUpdateModel.createSuperAdminAccounts = tenant.createSuperAdminAccounts;
    this.tenantUpdateModel.sendTrainingProgressMail =
      tenant.sendTrainingProgressMail;

    this.amount.hours = TimeService.getHoursFromAbsoluteMinutes(
      tenant.qualificationTargetIddTime || 0
    );
    this.amount.minutes = TimeService.getMinutesFromAbsoluteMinutes(
      tenant.qualificationTargetIddTime || 0
    );
    this.tenantUpdateModel.domains = domains;
  }
}
