<div class="settings-form">
  <app-page *ngIf="isDataLoaded">
    <div class="top-cta">
      <dx-button
        text="{{ 'COMMON.SAVE' | translate }}"
        type="normal"
        (click)="onFormSubmit()"
      >
      </dx-button>
    </div>
    <app-page-title
      [title]="'SETTINGS.OVERVIEW.TITLE' | translate"
    ></app-page-title>
    <app-panel
      class="settings-panel"
      [title]="'COMMON.FIELDS.QUALIFICATION-TARGET' | translate"
    >
      <dx-form
        labelMode="floating"
        [showColonAfterLabel]="false"
        [formData]="tenantUpdateModel"
      >
        <dxi-item itemType="group">
          <dxo-col-count-by-screen
            [xs]="1"
            [sm]="1"
            [md]="1"
            [lg]="2"
          ></dxo-col-count-by-screen>
          <dxi-item>
            <dxo-label [text]="'COMMON.FIELDS.AMOUNT' | translate"></dxo-label>
            <div *dxTemplate>
              <app-time-picker
                [isInvalid]="false"
                (timeChanged)="handleTimePicker($event)"
                [defaultValue]="amount"
              ></app-time-picker>
            </div>
          </dxi-item>
          <dxi-item
            [editorOptions]="qualificationTargetTypeOptions"
            dataField="qualificationTargetType"
            editorType="dxSelectBox"
          >
            <dxo-label
              [text]="'COMMON.FIELDS.DEFINITION' | translate"
            ></dxo-label>
          </dxi-item>
          <dxi-item dataField="iddResponsiblePersonId">
            <div *dxTemplate>
              <dx-drop-down-box
                #iddDropDown
                [(value)]="tenantUpdateModel.iddResponsiblePersonId"
                [inputAttr]="{ 'aria-label': 'Owner' }"
                valueExpr="id"
                displayExpr="name"
                [label]="'COMMON.FIELDS.IDD-RESPONSIBLE' | translate"
                [dataSource]="employees"
                labelMode="floating"
                stylingMode="outlined"
                [showClearButton]="true"
                (onValueChanged)="iddDropDown.instance.close()"
              >
                <div *dxTemplate="let data of 'content'">
                  <dx-tree-view
                    [searchEnabled]="true"
                    [dataSource]="employees"
                    keyExpr="id"
                    [selectNodesRecursive]="false"
                    displayExpr="name"
                    (onItemClick)="onIddResponsibleSelected($event)"
                  >
                  </dx-tree-view>
                </div>
              </dx-drop-down-box>
            </div>
          </dxi-item>
        </dxi-item>
        <dxi-item
          editorType="dxCheckBox"
          dataField="sendTrainingProgressMail"
          [editorOptions]="{
            text: 'SETTINGS.EMAIL-AUTOMATICALLY' | translate
          }"
        >
          <dxo-label [visible]="false"> </dxo-label>
        </dxi-item>
      </dx-form>
      <h2 class="settings-title">{{ "SETTINGS.LICENSES" | translate }}</h2>
      <dx-form
        labelLocation="top"
        [showColonAfterLabel]="false"
        [formData]="tenantUpdateModel"
      >
        <dxi-item itemType="group" colCount="3">
          <dxi-item
            [colSpan]="1"
            editorType="dxCheckBox"
            dataField="createEmployeeAccounts"
            [editorOptions]="{
              text: 'SETTINGS.ACCOUNTS-EMPLOYEES' | translate
            }"
          >
            <dxo-label [visible]="false"> </dxo-label>
          </dxi-item>
          <dxi-item [colSpan]="2" cssClass="missing-accounts-wrapper">
            <div *dxTemplate>
              <div class="missing-accounts" *ngIf="missingEmployeesData">
                <div class="missing-accounts-count">
                  {{ missingEmployeesData.numberOfPersons }}
                  {{ "SETTINGS.PERSONS-WITHOUT-ACCOUNT" | translate }}
                </div>
                <dx-button
                  [text]="'SETTINGS.CREATE-ACCOUNTS' | translate"
                  type="normal"
                  (click)="registerMissingAccounts(missingEmployeesData.roleId)"
                ></dx-button>
              </div>
            </div>
          </dxi-item>
        </dxi-item>
        <dxi-item itemType="group" colCount="3">
          <dxi-item
            [colSpan]="1"
            cssClass="checkboxes-wrapper"
            editorType="dxCheckBox"
            dataField="createManagerAccounts"
            [editorOptions]="{
              text: 'SETTINGS.ACCOUNTS-MANAGERS' | translate
            }"
          >
            <dxo-label [visible]="false"> </dxo-label>
          </dxi-item>
          <dxi-item [colSpan]="2" cssClass="missing-accounts-wrapper">
            <div *dxTemplate>
              <div class="missing-accounts" *ngIf="missingManagersData">
                <div class="missing-accounts-count">
                  {{ missingManagersData.numberOfPersons }}
                  {{ "SETTINGS.PERSONS-WITHOUT-ACCOUNT" | translate }}
                </div>
                <dx-button
                  [text]="'SETTINGS.CREATE-ACCOUNTS' | translate"
                  type="normal"
                  (click)="registerMissingAccounts(missingManagersData.roleId)"
                ></dx-button>
              </div>
            </div>
          </dxi-item>
        </dxi-item>
        <dxi-item itemType="group" colCount="3">
          <dxi-item
            [colSpan]="1"
            cssClass="checkboxes-wrapper"
            editorType="dxCheckBox"
            dataField="createTrainerAccounts"
            [editorOptions]="{
              text: 'SETTINGS.ACCOUNTS-TRAINERS' | translate
            }"
          >
            <dxo-label [visible]="false"> </dxo-label>
          </dxi-item>
          <dxi-item [colSpan]="2" cssClass="missing-accounts-wrapper">
            <div *dxTemplate>
              <div class="missing-accounts" *ngIf="missingTrainersData">
                <div class="missing-accounts-count">
                  {{ missingTrainersData.numberOfPersons }}
                  {{ "SETTINGS.PERSONS-WITHOUT-ACCOUNT" | translate }}
                </div>
                <dx-button
                  [text]="'SETTINGS.CREATE-ACCOUNTS' | translate"
                  type="normal"
                  (click)="registerMissingAccounts(missingTrainersData.roleId)"
                ></dx-button>
              </div>
            </div>
          </dxi-item>
        </dxi-item>
        <dxi-item itemType="group" colCount="3">
          <dxi-item
            [colSpan]="1"
            cssClass="checkboxes-wrapper"
            editorType="dxCheckBox"
            dataField="createSuperAdminAccounts"
            [editorOptions]="{
              text: 'SETTINGS.ACCOUNTS-SUPERADMINS' | translate
            }"
          >
            <dxo-label [visible]="false"> </dxo-label>
          </dxi-item>
          <dxi-item [colSpan]="2" cssClass="missing-accounts-wrapper">
            <div *dxTemplate>
              <div class="missing-accounts" *ngIf="missingSuperAdminsData">
                <div class="missing-accounts-count">
                  {{ missingSuperAdminsData.numberOfPersons }}
                  {{ "SETTINGS.PERSONS-WITHOUT-ACCOUNT" | translate }}
                </div>
                <dx-button
                  [text]="'SETTINGS.CREATE-ACCOUNTS' | translate"
                  type="normal"
                  (click)="registerMissingAccounts(missingSuperAdminsData.roleId)"
                ></dx-button>
              </div>
            </div>
          </dxi-item>
        </dxi-item>
      </dx-form>
        <h2 class="settings-title">{{ "SETTINGS.DOMAINS-TITLE" | translate }}</h2>
        <span class="settings-description">{{ "SETTINGS.DOMAINS-DESCRIPTION" | translate }}</span>
        <dx-form>
            <dxi-item itemType="group" colCount="3">
            <dxi-item [colSpan]="3" cssClass="missing-accounts-wrapper">
                <div *dxTemplate>
                    <div class="w-100">
                        <dx-tag-box
                                [items]="tenantUpdateModel.domains"
                                [(value)]="tenantUpdateModel.domains"
                                [acceptCustomValue]="true"
                                [inputAttr]="{ 'aria-label': 'Domain' }"
                                [openOnFieldClick]="false"
                        >
                            <div *dxTemplate="let tagData of 'tagTemplate'" class="dx-tag-content">
                                TAG CONTENT
                                <div class="dx-tag-remove-button">qwe</div>
                            </div>
                        </dx-tag-box>
                    </div>
                </div>
            </dxi-item>
            </dxi-item>
        </dx-form>
    </app-panel>
  </app-page>
</div>
