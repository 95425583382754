import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  DxButtonModule,
  DxCheckBoxModule,
  DxDropDownBoxModule,
  DxFormModule,
  DxListComponent,
  DxListModule,
  DxTemplateModule,
  DxTextBoxModule,
  DxTreeViewModule,
} from 'devextreme-angular';
import {
  DxiButtonModule,
  DxiItemModule,
  DxoLabelModule,
} from 'devextreme-angular/ui/nested';
import { NgForOf, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { OrganisationUnitModel } from '../../../../models/organisation-unit/organisation-unit.model';
import { PersonModel } from '../../../../models/person/person.model';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { OrganisationUnitService } from '../../../../services/organisation-unit.service';
import { TrainingMessageService } from '../../../../services/training-message.service';
import { LoadingService } from '../../../../services/loading.service';
import { OrganisationUnitTypeModel } from '../../../../models/organisation-unit/organisation-unit-type.model';
import { TrainingInvitationSearchModel } from '../../../../models/training/training-invitation-search.model';
import { PersonSummaryModel } from '../../../../models/person/person-summary.model';
import { TrainingService } from '../../../../services/training.service';
import { forkJoin, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { TrainingMeetingAttendeePersonModel } from '../../../../models/training/training-meeting-attendee-person.model';

@Component({
  selector: 'app-attendee-list',
  standalone: true,
  imports: [
    DxButtonModule,
    DxCheckBoxModule,
    DxDropDownBoxModule,
    DxFormModule,
    DxListModule,
    DxTemplateModule,
    DxTextBoxModule,
    DxTreeViewModule,
    DxiButtonModule,
    DxiItemModule,
    DxoLabelModule,
    NgForOf,
    TranslateModule,
    NgIf,
  ],
  templateUrl: './attendee-list.component.html',
  styleUrl: './attendee-list.component.scss',
})
export class AttendeeListComponent implements OnInit {
  @ViewChild('list', { static: true })
  list!: DxListComponent;

  organisationUnits: OrganisationUnitModel[] = [];
  locations: OrganisationUnitModel[] = [];
  allPersons: PersonModel[] = [];
  selectedPersons: PersonLike[] = [];
  page: number = 0;
  meetingAttendees: TrainingMeetingAttendeePersonModel[] = [];
  selectedPersonsSearchTerm: string = '';
  cachedFilteredSelectedPersons: PersonLike[] = [];

  isSearchLimit: boolean = false;

  formData = {
    locationFilter: [],
    organizationFilter: [],
    search: '',
  };

  @Input()
  trainingId!: string;

  store!: CustomStore;
  dataSource!: DataSource;
  protected readonly Math = Math;

  constructor(
    private organisationUnitService: OrganisationUnitService,
    private trainingInvitationService: TrainingMessageService,
    private loadingService: LoadingService,
    private trainingService: TrainingService
  ) {}

  ngOnInit(): void {
    const data = this.loadingService
      .load(
        forkJoin({
          training: this.trainingService.getTraining(this.trainingId),
          eventMembers: this.trainingService.getEventMembers(this.trainingId),
          meetingAttendees: this.trainingService
            .getTrainingMeetingAttendees(this.trainingId)
            .pipe(catchError(() => of([]))),
          organizations: this.organisationUnitService.getAllOrganisationUnits(),
        })
      )
      .pipe(shareReplay(1));

    data.subscribe(
      ({ training, eventMembers, meetingAttendees, organizations }) => {
        this.organisationUnits = organizations.filter(
          (item) => item.type === OrganisationUnitTypeModel.Department
        );
        this.locations = organizations.filter(
          (item) => item.type === OrganisationUnitTypeModel.Location
        );
        this.doSearch(true);
        this.selectedPersons = eventMembers.map((em) => em.person);
        this.meetingAttendees = meetingAttendees;

        const locationType = training.training.trainingLocation.type;
        if (locationType != 0 && locationType != 1) {
          this.selectedPersons = meetingAttendees.map((m) => m.person);
        }
        this.updateFilteredSelectedPersons();
      }
    );
  }

  onLocationsSelected(e: any) {
    this.formData.locationFilter = e.component.getSelectedNodeKeys();
    this.doSearch(true);
  }

  onOrganizationSelected(e: any) {
    this.formData.organizationFilter = e.component.getSelectedNodeKeys();
    this.doSearch(true);
  }

  onSerachValueChanged(e: any) {
    this.doSearch(true);
  }

  getMeetingAttendeeByPersonId(
    id: string
  ): TrainingMeetingAttendeePersonModel | undefined {
    return this.meetingAttendees.find((attendee) => attendee.person.id === id);
  }

  doSearch(resetSearch: boolean): void {
    if (!resetSearch && this.isSearchLimit) {
      return;
    } else if (resetSearch) {
      this.isSearchLimit = false;
    }

    const page = resetSearch ? 0 : this.page + 1;
    const model: TrainingInvitationSearchModel = {
      page,
      searchText: this.formData.search,
      organisationUnitIdFilter: this.formData.organizationFilter,
    };

    this.loadingService
      .load(this.trainingInvitationService.searchPersonsForInvitation(model))
      .subscribe((result) => {
        if (!result.persons.length) {
          this.isSearchLimit = true;
        }

        this.page = result.page;
        this.allPersons = resetSearch
          ? result.persons
          : this.allPersons.concat(result.persons);
      });
  }

  onPersonSelected(e: any, person: PersonModel) {
    if (!this.isSelected(person)) {
      this.selectedPersons.push(person);
    } else {
      this.selectedPersons = this.selectedPersons.filter(
        (selected: PersonLike) => selected.id !== person.id
      );
    }
    this.updateFilteredSelectedPersons();
  }

  isAllPersonsSelected() {
    return (
      this.allPersons.filter(
        (person) => !this.selectedPersons.map((sp) => sp.id).includes(person.id)
      ).length > 0
    );
  }

  onAllEmployeesClick(e: any) {
    if (this.selectedPersons.length > 0) {
      this.selectedPersons = [];
    } else {
      this.selectedPersons = this.allPersons;
    }
    this.updateFilteredSelectedPersons();
  }

  onRemoveClicked(person: PersonModel | PersonSummaryModel) {
    this.selectedPersons = this.selectedPersons.filter(
      (selected: PersonLike) => selected.id !== person.id
    );
    this.updateFilteredSelectedPersons();
  }

  unselectAll(): void {
    this.selectedPersons = [];
    this.updateFilteredSelectedPersons();
  }

  isSelected(person: PersonLike): boolean {
    return (
      this.selectedPersons.filter(
        (selectedPerson) => selectedPerson.id == person.id
      ).length > 0
    );
  }

  onScroll(event: any) {
    const element = event.target;
    // Check if the user has scrolled to the bottom
    if (element.scrollHeight - element.scrollTop <= element.clientHeight + 1) {
      this.doSearch(false);
    }
  }

  formatDuration(attendanceDurationSeconds: number): string {
    const hours = Math.floor(attendanceDurationSeconds / 3600);
    const minutes = Math.floor((attendanceDurationSeconds % 3600) / 60);
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}`;
  }

  updateFilteredSelectedPersons(): void {
    const searchTerm = this.selectedPersonsSearchTerm.toLowerCase();
    this.cachedFilteredSelectedPersons = this.selectedPersons
      .filter(
        (person) =>
          person.firstName.toLowerCase().includes(searchTerm) ||
          person.lastName.toLowerCase().includes(searchTerm)
      )
      .sort((a, b) => {
        const lastNameA = a.lastName.toLowerCase();
        const lastNameB = b.lastName.toLowerCase();

        if (lastNameA < lastNameB) {
          return -1;
        }
        if (lastNameA > lastNameB) {
          return 1;
        }

        const firstNameA = a.firstName.toLowerCase();
        const firstNameB = b.firstName.toLowerCase();

        if (firstNameA < firstNameB) {
          return -1;
        }
        if (firstNameA > firstNameB) {
          return 1;
        }

        return 0;
      });
  }

  onSelectedPersonsSearchTermChange(): void {
    this.updateFilteredSelectedPersons();
  }
}

interface PersonLike {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}
