<div class="reporting-info" *ngIf="reportData">
  <app-page>
    <div class="top-cta">
      <dx-button
        [text]="'COMMON.BACK' | translate"
        icon="back"
        type="default"
        class="back-btn"
        (click)="navigateBack()"
      >
      </dx-button>
      <div class="right-actions">
        <dx-select-box
          labelMode="floating"
          stylingMode="outlined"
          [label]="'COMMON.FIELDS.YEAR' | translate"
          placeholder=""
          displayExpr="name"
          valueExpr="id"
          [dataSource]="yearsDropdownDataSource"
          (onValueChanged)="onYearFilterChanged($event)"
          [value]="yearFilter"
        >
        </dx-select-box>
      </div>
    </div>
    <app-page-title
      [title]="'DASHBOARD.REPORTING.COMPLIANCE-REPORT' | translate"
    ></app-page-title>
    <div class="row">
      <div class="col-xs-12">
        <app-table
          *ngIf="reportData"
          [data]="reportData"
          [columns]="reportColumns"
          [showExport]="true"
          [showGrouping]="true"
        ></app-table>
      </div>
    </div>
  </app-page>
</div>
