<dx-popup
  [width]="1200"
  [height]="'auto'"
  [maxHeight]="500"
  [showTitle]="true"
  [title]="'EMPLOYEE.OVERVIEW.MERGE-ACCOUNTS' | translate"
  [dragEnabled]="false"
  [hideOnOutsideClick]="false"
  [showCloseButton]="true"
  class="modal"
  [classList]="'modal'"
  [copyRootClassesToWrapper]="false"
  [visible]="showModal"
  (onHidden)="dismiss()"
>
  <div *dxTemplate="let data of 'content'" class="modal-content">
    <div class="modal-text">
      <p>{{ "EMPLOYEE.OVERVIEW.MERGE-ACCOUNTS-TEXT1" | translate }}</p>
      <p>{{ "EMPLOYEE.OVERVIEW.MERGE-ACCOUNTS-TEXT2" | translate }}</p>
    </div>
    <app-table
      [data]="persons"
      [columns]="personsColumns"
      [showSelection]="true"
      selectionMode="single"
      [showSearch]="false"
      (selectHandler)="onPersonSelected($event)"
    ></app-table>
    <div class="modal-buttons">
      <dx-button
        class="cta-btn"
        type="default"
        [text]="'COMMON.CANCEL' | translate"
        (click)="dismiss()"
      ></dx-button>
      <dx-button
        class="cta-btn"
        type="normal"
        [disabled]="!selectedPerson"
        [text]="'EMPLOYEE.OVERVIEW.MERGE-NOW' | translate"
        (click)="onSubmit()"
      ></dx-button>
    </div>
  </div>
</dx-popup>
