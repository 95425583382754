<dx-form
  #addCertificateForm
  [formData]="certificateForm"
  [showColonAfterLabel]="false"
  labelMode="floating"
>

  <dxi-item itemType="group">
    <dxo-col-count-by-screen
      [xs]="1"
      [sm]="1"
      [md]="1"
      [lg]="4"
    ></dxo-col-count-by-screen>
    <dxi-item dataField="certificateFile" colSpan="4">
      <div *dxTemplate>
        <div id="dropzone-external" class="dropzone" [ngClass]="isDropZoneActive ? 'dropzone-active' : null">
          <div class="dropzone-image-container">
            <img class="dropzone-image" src="assets/img/logo-ai.png" alt="upload-icon" />
          </div>
          <div class="dropzone-text">
            <span>{{"COMMON.UPLOAD-AI-DRAG-DROP-1" | translate}}</span>
            <span>{{"COMMON.UPLOAD-AI-DRAG-DROP-2" | translate}}</span>
          </div>
        </div>




        <div class="certificate-uploader-wrapper">
          <dx-file-uploader
            #filesUploader
            dialogTrigger="#dropzone-external"
            dropZone="#dropzone-external"
            class="certificate-uploader"
            [selectButtonText]="'COMMON.CHOOSE-FILE' | translate"
            [labelText]="'COMMON.UPLOAD-ATTACHMENT' | translate"
            [multiple]="false"
            accept="application/pdf"
            (valueChange)="onSelectedFileChange($event)"
            uploadMode="useButtons"
            (onDropZoneEnter)="onDropZoneEnter($event)"
            (onDropZoneLeave)="onDropZoneLeave($event)"
            (onUploaded)="onUpload($event)"
          >
          </dx-file-uploader>
          <div *ngIf="certificateForm.certificateFile" (click)="removeFile()">
            <i class="dx-icon-close"></i>
          </div>
        </div>




      </div>
    </dxi-item>
  </dxi-item>

  <dxi-item dataField="name">
    <dxo-label [text]="'COMMON.FIELDS.TITLE' | translate"></dxo-label>
    <dxi-validation-rule
      type="required"
      [message]="'VALIDATIONS.TITLE-REQUIRED' | translate"
    ></dxi-validation-rule>
  </dxi-item>
  <dxi-item dataField="description">
    <div *dxTemplate>
      <dx-text-area
        labelMode="floating"
        [label]="'COMMON.FIELDS.DESCRIPTION' | translate"
        class="certificate-description"
        [(value)]="certificateForm.description!"
      ></dx-text-area>
    </div>
  </dxi-item>
  <dxi-item itemType="group">
    <dxo-col-count-by-screen
      [xs]="1"
      [sm]="1"
      [md]="1"
      [lg]="2"
    ></dxo-col-count-by-screen>
    <dxi-item
      [editorOptions]="trainingTypeOptions"
      dataField="trainingType"
      editorType="dxSelectBox"
    >
      <dxo-label [text]="'COMMON.FIELDS.TRAINING-TYPE' | translate"></dxo-label>
      <dxi-validation-rule
        type="required"
        [message]="'VALIDATIONS.TRAINING-TYPE-REQUIRED' | translate"
      ></dxi-validation-rule>
    </dxi-item>
    <dxi-item dataField="trainingGroupId">
      <div *dxTemplate>
        <dx-drop-down-box
          #trainingGroupDropDown
          [(value)]="certificateForm.trainingGroupId"
          [inputAttr]="{ 'aria-label': 'Owner' }"
          valueExpr="id"
          displayExpr="name"
          [label]="('COMMON.FIELDS.TRAINING-GROUP' | translate) + (certificateForm.trainingType == 0 ? ' *' : '')"
          [dataSource]="trainingGroups"
          labelMode="floating"
          stylingMode="outlined"
          (onValueChanged)="trainingGroupDropDown.instance.close()"
        >
          <dx-validator validationGroup="customFields">
            <dxi-validation-rule
              *ngIf="certificateForm.trainingType == 0"
              type="required"
              [message]="'VALIDATIONS.TRAINING-GROUP-REQUIRED' | translate"
            ></dxi-validation-rule>
          </dx-validator>
          <div *dxTemplate="let data of 'content'">
            <dx-tree-view
              [searchEnabled]="true"
              [dataSource]="trainingGroups"
              keyExpr="id"
              [selectNodesRecursive]="false"
              displayExpr="name"
              (onItemClick)="onTrainingGroupSelected($event)"
            >
            </dx-tree-view>
          </div>
        </dx-drop-down-box>
      </div>
    </dxi-item>
    <dxi-item dataField="date" editorType="dxDateBox">
      <dxo-label [text]="'COMMON.FIELDS.DATE' | translate"></dxo-label>
      <dxi-validation-rule
        type="required"
        [message]="'VALIDATIONS.DATE-REQUIRED' | translate"
      ></dxi-validation-rule>
    </dxi-item>
    <!-- <dxi-item *ngIf="certificateForm.trainingType === 0" dataField="iddTimeMinutes" editorType="dxNumberBox">
      <dxo-label [text]="'COMMON.FIELDS.TRAINING-TIME' | translate"></dxo-label>
    </dxi-item> -->
    <dxi-item *ngIf="certificateForm.trainingType === 0">
      <app-time-picker
        [isInvalid]="false"
        [disabled]="false"
        (timeChanged)="handleTimePicker($event)"
        [defaultValue]="{
          hours: certificateForm.iddHours!,
          minutes: certificateForm.iddMinutes!
        }"
      ></app-time-picker>
    </dxi-item>
    <!-- <dxi-item dataField="location">
      <dxo-label [text]="'COMMON.FIELDS.LOCATION' | translate"></dxo-label>
    </dxi-item> -->
    <dxi-item dataField="location">
      <div *dxTemplate>
        <dx-autocomplete
          [(text)]="googleSearchText"
          [searchExpr]="autocompleteSearchFilter"
          [label]="'COMMON.FIELDS.TRAINING-LOCATION' | translate"
          [label]="'TRAINING.FORM.SEARCH-TRAINING-LOCATION' | translate"
          [dataSource]="googlePlaces"
          valueExpr="description"
          (onValueChanged)="getGooglePlaces($event)"
          (onItemClick)="setLocationPlace($event)"
          [readOnly]="false"
          labelMode="floating"
          stylingMode="outlined"
        >
        </dx-autocomplete>
      </div>
    </dxi-item>
  </dxi-item>
  
</dx-form>
<div class="cta-button">
  <dx-button
    [text]="'COMMON.SAVE' | translate"
    type="normal"
    (click)="onFormSubmit()"
  ></dx-button>
</div>
