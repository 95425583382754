import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoadingService } from 'src/app/services/loading.service';
import { IntegrationsService } from '../../../services/integration/integrations.service';
import { TrainingLocationTypeModel } from '../../../models/training/training-location-type.model';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrl: './team.component.css'
})
export class TeamComponent {
  constructor(
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService,
    private router: Router,
    private toastrService: ToastrService,
    private integrationsService: IntegrationsService
  ) {}

  ngOnInit(): void {
    debugger
    const code = this.activatedRoute.snapshot.queryParams['code'];
    const obs = this.integrationsService.integrate(
      TrainingLocationTypeModel.MsTeams,
      code,
      IntegrationsService.teamRedirectUrl
    );
    this.loadingService.load(obs).subscribe(() => {
      this.toastrService.success('Teams Integration erfolgreich');
      this.router.navigate(['integrations']);
    });
  }
}
