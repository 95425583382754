export enum TrainingLocationTypeModel
{
  Address = 0,
  Web = 1,
  Webex = 2,
  Webinar = 3,
  GotoMeeting = 4,
  MsTeams = 5,
  GoToWebinar = 6,
  Zoom = 7
}
