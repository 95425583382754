<!-- <dx-map
  [center]="center"
  [zoom]="zoom"
  [height]="297"
  width="100%"
  [provider]="'google'"
 [apiKey]="apiKey"
  markerIconSrc="assets/img/map-pin.svg"
  [markers]="markers"
> 
</dx-map> -->

  <div >
    <iframe [src]="googleMapUrl" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
  </div>