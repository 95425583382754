import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular";
import {AppModule} from './app/app.module';
import { environment } from './environments/environment';

Sentry.init({
  dsn: environment.sentryDsn,
  integrations: [],
});

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
