import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DatePipe } from '@angular/common';
import { CompletedEventSummaryModel } from '../../models/completed-event/completed-event-summary.model';
import { TrainingTypeDisplayPipe } from '../../pipes/training-type-display.pipe';
import { TimeService } from '../../services/time.service';
import { LoadingService } from '../../services/loading.service';
import { PersonSummaryModel } from '../../models/person/person-summary.model';
import { TableColumn } from '../../models/table-column.model';
import { EventTypeModel } from '../../models/event-type.model';
import { CompletedEventMemberService } from '../../services/completed-event-member.service';
import { ConfirmationDialogData } from 'src/app/components/shared/confirmation-dialog/confirmation-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-completed-event-table',
  templateUrl: './completed-event-table.component.html',
  styleUrls: ['./completed-event-table.component.scss'],
})
export class CompletedEventTableComponent {
  @Input()
  editingPersonId!: string;

  @Input()
  isSuperAdmin!: boolean;

  @Input()
  completedEvents: CompletedEventSummaryModel[] = [];

  @Output()
  refreshEvents = new EventEmitter();

  isEditMode: boolean = false;
  externalCertificatePopupVisible = false;
  currentCertificate: any = null;
  disabledButtons = new Set<string>();

  eventsColumns: TableColumn[] = [
    {
      caption: this.translateService.instant('COMMON.FIELDS.TITLE'),
      dataField: 'title',
      cssClass: 'width-eq-500',
    },
    {
      caption: this.translateService.instant('COMMON.FIELDS.DATE'),
      dataField: 'date',
      dataType: 'date',
      cellValue: (data: any): any => {
        return new Date(data.date);
      },
      calculateSortValue: (data: CompletedEventSummaryModel): Date => {
        return new Date(data.date);
      },
      calculateDisplayValue: (data: CompletedEventSummaryModel): string => {
        return (
          new DatePipe(this.translateService.currentLang).transform(
            new Date(data.date),
            'dd.MM.YYYY'
          ) || ''
        );
      },


      // cellValue: (data: any): any => {
      //   if (data && data.dates && data.dates[0] && data.dates[0].start) {
      //     return new Date(data.dates[0].start)
      //   } else {
      //     return 0;
      //   }
      // },
      // calculateDisplayValue: (data: TrainingSummaryModel): string => {
      //   const firstStartDate = new FirstStartDatePipe().transform(data.dates);
      //   if (!firstStartDate) {
      //     return '';
      //   } else {
      //     return (
      //       new DatePipe(this.translateService.currentLang).transform(
      //         firstStartDate,
      //         'EEEE, dd MMMM YYYY HH:mm a'
      //       ) || ''
      //     );
      //   }
      // },
      // calculateSortValue: (data: TrainingSummaryModel): Date | number => {
      //   if (data.dates && data.dates[0] && data.dates[0].start) {
      //     return new Date(data.dates[0].start);
      //   } else {
      //     return 0;
      //   }
      // }
    },
    {
      caption: this.translateService.instant('COMMON.FIELDS.TRAINING-TYPE'),
      dataField: 'trainingType',
      cellValue: (data: CompletedEventSummaryModel): string => {
        return this.trainingTypeDisplayPipe.transform(data.trainingType);
      },
    },
    {
      caption: this.translateService.instant('COMMON.FIELDS.HOURS'),
      dataField: 'iddPoints',
      cellValue: (data: CompletedEventSummaryModel): string => {
        if (data.iddTimeMinutes === 0) {
          return '';
        }
        return TimeService.getHoursAndMinutesDisplayStringFromIddTimeMinutes(
          data.iddTimeMinutes
        );
      },
    },
    {
      caption: this.translateService.instant('COMMON.FIELDS.TRAINING-GROUPS'),
      dataField: 'trainingGroups',
      headerFilter: (data: any) => {
        data.dataSource.postProcess = (results: any) => {
          const trainingGroupNames = results.reduce(
            (a: any, b: any) => a.concat(b.value),
            []
          );
          const parsedTrainingGroupNames: string[] = [];
          trainingGroupNames.forEach((group: string) => {
            if (!group || !group.includes(', ')) {
              parsedTrainingGroupNames.push(group);
            } else if (group.includes(', ')) {
              const groups = group.split(', ');
              parsedTrainingGroupNames.push(...groups);
            }
          });
          const uniqueTrainingGroupNames = [
            ...new Set(parsedTrainingGroupNames),
          ];

          results.splice(0, results.length);
          uniqueTrainingGroupNames.forEach((tgName) => {
            if (tgName) {
              results.push({
                text: tgName,
                value: tgName,
              });
            } else {
              results.push({
                text: '(Blanks)',
                value: null,
              });
            }
          });
          return results;
        };
      },
      filterExpression: (value: any) => {
        function getTrainingGroupsFilterString(rowData: PersonSummaryModel) {
          const groups = rowData.trainingGroups.map((tg) => tg.name);
          return !!groups.join(',') ? groups.join(',') : 'empty';
        }

        return [[getTrainingGroupsFilterString, 'contains', value || 'empty']];
      },
      cellValue: (data: PersonSummaryModel | any): string => {
        return data.trainingGroups.map((tg: any) => tg.name).join(', ');
      },
    },
    {
      caption: '',
      dataField: 'this',
      cellType: 'action',
      disableFiltering: true,
      cellTemplate: 'actionsTemplate',
      actions: [
        {
          icon: null,
          text: this.translateService.instant('COMMON.WELL-ADVISED'),
          isShowAction: (
            completedEventSummary: CompletedEventSummaryModel
          ): boolean => {
            return completedEventSummary.eventType === EventTypeModel.GutBeraten;
          },
          onClick: (): void => {},
        },
        {
          icon: 'edit',
          isShowAction: (
            completedEventSummary: CompletedEventSummaryModel
          ): boolean => {
            return completedEventSummary.eventType === EventTypeModel.External;
          },
          onClick: (
            completedEventSummary: CompletedEventSummaryModel
          ): void => {
            this.completedEventSummaryId = completedEventSummary.id;
            this.currentCertificate = this.completedEvents.find(event => event.id == completedEventSummary.id);
            this.showEditCerificatePopup();
          },
        },
        {
          icon: 'pdffile',
          isDisabledAction: (
            completedEventSummary: CompletedEventSummaryModel
          ): boolean => {
            if (
              this.disabledButtons.has(
                completedEventSummary.completedEventMemberId
              )
            ) {
              return true;
            } else {
              return false;
            }
          },
          isShowAction: (
            completedEventSummary: CompletedEventSummaryModel
          ): boolean => {
            return (
              completedEventSummary.eventType === EventTypeModel.Normal ||
              completedEventSummary.eventType === EventTypeModel.Webinar ||
              (completedEventSummary.isExternalCertificateAvailable &&
                completedEventSummary.eventType == EventTypeModel.External)
            );
          },
          onClick: (
            completedEventSummary: CompletedEventSummaryModel
          ): void => {
            if (
              this.disabledButtons.has(
                completedEventSummary.completedEventMemberId
              )
            ) {
              return;
            }
            this.disabledButtons.add(
              completedEventSummary.completedEventMemberId
            );
            this.completedEventMemberService.downloadCertificate(
              completedEventSummary.completedEventMemberId
            );
            setTimeout(() => {
              this.disabledButtons.delete(
                completedEventSummary.completedEventMemberId
              );
            }, 2000);
          },
        },
        {
          icon: 'trash',
          isShowAction: (
            completedEventSummary: CompletedEventSummaryModel
          ): boolean => {
            return completedEventSummary.eventType === EventTypeModel.External;
          },
          onClick: (
            completedEventSummary: CompletedEventSummaryModel
          ): void => {
            this.completedEventSummaryId = completedEventSummary.id;
            this.openDeleteTrainingConfirmationModal();
          },
        }
      ],
    },
  ];

  modalOptions: ConfirmationDialogData | null = null;
  showModal = false;
  completedEventSummaryId: string | null = null;

  constructor(
    private trainingTypeDisplayPipe: TrainingTypeDisplayPipe,
    private completedEventMemberService: CompletedEventMemberService,
    private translateService: TranslateService,
    private loadingService: LoadingService
  ) {}

  onEventCreated() {
    this.externalCertificatePopupVisible = false;
    this.refreshEvents.emit();
  }

  showAddCerificatePopup(): void {
    this.isEditMode = false;
    this.externalCertificatePopupVisible = true;
  }

  showEditCerificatePopup(): void {
    this.isEditMode = true;
    this.externalCertificatePopupVisible = true;
  }

  openDeleteTrainingConfirmationModal() {
    this.modalOptions = {
      submitButtonText: this.translateService.instant('COMMON.YES'),
      message: this.translateService.instant(
        'TRAINING.DELETE-TRAINING-DIALOG.TEXT'
      ),
      dismissButtonText: this.translateService.instant('COMMON.NO'),
    };
    this.showModal = true;
  }

  deleteEvent() {
    if (!this.completedEventSummaryId) return;

    this.loadingService
      .load(
        this.completedEventMemberService.deleteCertificate(
          this.completedEventSummaryId!
        )
      )
      .subscribe(() => {
        this.refreshEvents.emit();
        this.completedEventSummaryId = null;
      });
  }
}
