import { Component, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { OrganisationUnitService } from '../../../services/organisation-unit.service';
import { forkJoin } from 'rxjs';
import { OrganisationUnitModel } from '../../../models/organisation-unit/organisation-unit.model';
import { first, shareReplay } from 'rxjs/operators';
import { OrganisationUnitTypeModel } from '../../../models/organisation-unit/organisation-unit-type.model';
import { TrainingGroupModel } from '../../../models/training-group.model';
import { TimeZoneModel } from '../../../models/time-zone.model';
import { RoleModel } from '../../../models/person/role.model';
import { PersonService } from '../../../services/person.service';
import { UserService } from '../../../services/user.service';
import { TrainingGroupService } from '../../../services/training-group.service';
import { TimeZoneService } from '../../../services/time-zone.service';
import { RoleService } from '../../../services/role.service';
import { PersonCreationAndUpdateModel } from '../../../models/person/person-creation-and-update.model';
import { ActivatedRoute, Router } from '@angular/router';
import { PersonFullModel } from '../../../models/person/person-full.model';
import { ToastrService } from 'ngx-toastr';
import { LoadingService } from '../../../services/loading.service';
import { CompletedEventService } from '../../../services/completed-event.service';
import { CompletedEventSummaryModel } from '../../../models/completed-event/completed-event-summary.model';
import { UserModel } from 'src/app/models/person/user.model';
import { ManageUserModel } from 'src/app/models/person/manage-user.model';
import { DxSelectBoxTypes } from 'devextreme-angular/ui/select-box';
import { DxFormComponent } from 'devextreme-angular/ui/form';
import { DxTabsTypes } from 'devextreme-angular/ui/tabs';
import { AuthorizationService } from '../../../services/authorization.service';
import { FileUtilService } from '../../../services/file-util.service';
import { ConfirmationDialogData } from 'src/app/components/shared/confirmation-dialog/confirmation-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { TimeService } from '../../../services/time.service';
import validationEngine from 'devextreme/ui/validation_engine';
import { DxDropDownBoxComponent } from 'devextreme-angular';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-employee-form',
  templateUrl: './employee-form.component.html',
  styleUrls: ['./employee-form.component.scss'],
})
export class EmployeeFormComponent {
  @ViewChild('topForm', { static: true }) topForm!: DxFormComponent;
  @ViewChild('bottomForm', { static: true }) bottomForm!: DxFormComponent;
  @ViewChild(DxDropDownBoxComponent, { static: false })
  locationDropDown!: DxDropDownBoxComponent;
  @ViewChild(DxDropDownBoxComponent, { static: false })
  departmentDropDown!: DxDropDownBoxComponent;

  isOverviewPopupVisible: boolean = false;
  editingPersonId!: string;
  isProfilePage: boolean = false;
  isManager: boolean = true;
  isSuperAdmin: boolean = true;
  isInactive: boolean = false;
  isCurrentUser: boolean = false;
  isUserHasAccount: boolean = false;
  selectedTab: number = 0;
  tabs: any[] = [
    {
      id: 0,
      text: this.translateService.instant('EMPLOYEE.FORM.PROFILE-TAB'),
    },
    {
      id: 1,
      text: this.translateService.instant('EMPLOYEE.FORM.TRAININGS-TAB'),
    },
  ];
  organizationUnits!: OrganisationUnitModel[];
  locations!: OrganisationUnitModel[];
  trainingGroups!: TrainingGroupModel[];
  timeZones!: TimeZoneModel[];
  roles!: RoleModel[];
  completedEvents!: CompletedEventSummaryModel[];
  employeeForm: PersonCreationAndUpdateModel = {
    firstName: null,
    lastName: null,
    locationId: null,
    trainingGroupIds: [],
    city: null,
    culture: null,
    email: null,
    iddNumber: null,
    agentNumber: null,
    phoneBusiness: null,
    phoneMobile: null,
    phonePrivate: null,
    roleId: null,
    street: null,
    timeZone: null,
    zipCode: null,
    imageBase64: null,
    emailSignature: null,
  };

  public iddHoursReachedString: string = '00:00';

  rolesOptions!: DxSelectBoxTypes.Properties;
  timeZonesOptions!: DxSelectBoxTypes.Properties;
  languagesOptions!: DxSelectBoxTypes.Properties;

  modalOptions: ConfirmationDialogData | null = null;
  showModal = false;
  currentModalID: 'addUser' | 'changePassword' | null = null;

  constructor(
    private organisationUnitService: OrganisationUnitService,
    private personService: PersonService,
    private trainingGroupService: TrainingGroupService,
    private timeZoneService: TimeZoneService,
    private roleService: RoleService,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private router: Router,
    private loadingService: LoadingService,
    private completedEventService: CompletedEventService,
    private userService: UserService,
    private authorizationService: AuthorizationService,
    private fileUtilService: FileUtilService,
    private translateService: TranslateService,
    private location: Location
  ) {
    const tab: string | null = this.route.snapshot.queryParams['tab'];

    if (tab && tab === 'trainings') {
      this.selectedTab = 1;
    }

    const formData = this.loadingService
      .load(
        forkJoin({
          organizationUnits:
            this.organisationUnitService.getAllOrganisationUnits(),
          trainingGroups: this.trainingGroupService.getAllTrainingGroups(),
          timeZones: this.timeZoneService.getAllTimeZones(),
          roles: this.roleService.getAllRoles(),
        })
      )
      .pipe(shareReplay(1));

    formData.subscribe(
      ({ organizationUnits, trainingGroups, timeZones, roles }) => {
        this.organizationUnits = organizationUnits;
        this.locations = organizationUnits;
        this.trainingGroups = trainingGroups;
        this.timeZones = timeZones;
        this.roles = roles;
        this.roles.forEach((role) => {
          if (role.name == 'Employee') {
            role.name = this.translateService.instant('COMMON.EMPLOYEE');
          }
        });

        this.editingPersonId = this.route.snapshot.params['id'];
        const path = this.route.snapshot.routeConfig!.path!;
        if (path.includes('profile')) {
          this.isProfilePage = true;
        }

        if (!this.editingPersonId && !this.isProfilePage) {
          this.setDropdownOptions();
        }

        if (this.editingPersonId || this.isProfilePage) {
          this.authorizationService
            .getCurrentPerson()
            .pipe(first())
            .subscribe((currentUser) => {
              if (this.isProfilePage) {
                this.editingPersonId = currentUser.person.id;
              }

              if (
                currentUser.person.role.name !== 'Manager' &&
                currentUser.person.role.name !== 'SuperAdmin'
              ) {
                this.isManager = false;
              }

              if (currentUser.person.role.name !== 'SuperAdmin') {
                this.isSuperAdmin = false;
                this.roles = this.roles.filter(
                  (role) => role.name !== 'SuperAdmin'
                );
              }

              this.setDropdownOptions();

              const personData = this.loadingService
                .load(
                  forkJoin({
                    person: this.personService.getPerson(this.editingPersonId),
                    iddTimeMinutes: this.personService.getPersonIddTimeMinutes(
                      this.editingPersonId
                    ),
                    completedEvents:
                      this.completedEventService.getAllCompletedEvents(
                        this.editingPersonId!
                      ),
                  })
                )
                .pipe(shareReplay(1));

              personData.subscribe(
                ({ person, completedEvents, iddTimeMinutes }) => {
                  this.patchForm(person);
                  this.completedEvents = this.sortEventsByDate(completedEvents);

                  this.iddHoursReachedString =
                    TimeService.getHoursAndMinutesDisplayStringFromIddTimeMinutes(
                      iddTimeMinutes
                    );
                  if (currentUser.person.id === person.person.id) {
                    this.isCurrentUser = true;
                    this.tabs.push({
                      id: 2,
                      text: this.translateService.instant(
                        'EMPLOYEE.FORM.EMAIL-SIGNATURE-TAB'
                      ),
                    });
                  }

                  if (person.user) {
                    this.patchFormUser(person.user);
                  }
                }
              );
            });
        }
      }
    );
  }

  sortEventsByDate(
    events: CompletedEventSummaryModel[]
  ): CompletedEventSummaryModel[] {
    return events.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);

      if (dateA > dateB) {
        return -1;
      }
      if (dateA < dateB) {
        return 1;
      }
      return 0;
    });
  }

  navigateBack(): void {
    this.router.navigate(['employee']);
  }

  nullifyEmptyFields(obj: any) {
    Object.keys(obj).forEach((key: string) => {
      if (!obj[key]) {
        obj[key] = null;
      }
    });
  }

  onFormSubmit(): void {
    const isTopFormValid = this.topForm.instance.validate().isValid;
    const isBottomFormValid = this.bottomForm.instance.validate().isValid;
    const isCustomFieldsValid =
      validationEngine.validateGroup('customFields').isValid;

    if (isTopFormValid && isBottomFormValid && isCustomFieldsValid) {
      if (!this.editingPersonId) {
        const employee = {
          ...this.employeeForm,
          imageBase64: this.employeeForm.imageBase64
            ? this.employeeForm.imageBase64!.split(',')[1]
            : null,
        };
        this.nullifyEmptyFields(employee);

        const result = this.personService.addPerson(employee);
        this.loadingService.load(result).subscribe({
          next: (createdPerson) => {
            this.toastrService.success(
              this.translateService.instant('EMPLOYEE.FORM.EMPLOYEE-CREATED')
            );
            this.router.navigate(['employee']);
          },
          error: (err: HttpErrorResponse) => {
            if (err.status === 409 && err.error == 'duplicate-email') {
              this.toastrService.warning(
                this.translateService.instant(
                  'EMPLOYEE.FORM.DUPLICATE-EMAIL-ERROR'
                )
              );
            } else {
              this.toastrService.error(
                this.translateService.instant('COMMON.AN-ERROR-HAS-OCCURRED')
              );
            }
          },
        });
      } else {
        const employee: any = {
          ...this.employeeForm,
          imageBase64: this.employeeForm.imageBase64
            ? this.employeeForm.imageBase64!.split(',')[1]
            : null,
        };
        this.nullifyEmptyFields(employee);

        if (!employee.imageBase64) {
          delete employee.imageBase64;
        }

        const result = this.personService.updatePerson(
          this.editingPersonId,
          employee
        );
        this.loadingService.load(result).subscribe({
          next: (updatedPerson) => {
            this.toastrService.success(
              this.translateService.instant('EMPLOYEE.FORM.EMPLOYEE-UPDATED')
            );
            window.location.reload();
          },
          error: (err: HttpErrorResponse) => {
            if (err.status === 409 && err.error == 'duplicate-email') {
              this.toastrService.warning(
                this.translateService.instant(
                  'EMPLOYEE.FORM.DUPLICATE-EMAIL-ERROR'
                )
              );
            } else {
              this.toastrService.error(
                this.translateService.instant('COMMON.AN-ERROR-HAS-OCCURRED')
              );
            }
          },
          // (updatedPerson) => {
          // this.toastrService.success(
          //   this.translateService.instant('EMPLOYEE.FORM.EMPLOYEE-UPDATED')
          // );
          // window.location.reload();
        });
      }
    }
  }

  setDropdownOptions() {
    const defaultSelectOptions = {
      showClearButton: true,
      stylingMode: 'outlined',
      displayExpr: 'name',
      valueExpr: 'id',
    } as DxSelectBoxTypes.Properties;

    this.rolesOptions = {
      ...defaultSelectOptions,
      dataSource: this.roles,
      placeholder: '',
      disabled: !this.isManager,
    };
    this.timeZonesOptions = {
      ...defaultSelectOptions,
      dataSource: this.timeZones,
      placeholder: '',
    };
    this.languagesOptions = {
      ...defaultSelectOptions,
      dataSource: [
        { id: 'en', name: 'English' },
        { id: 'de', name: 'Deutsch' },
      ],
      placeholder: '',
    };

    if (!this.editingPersonId && !this.isProfilePage) {
      this.employeeForm.culture = 'de';
      this.employeeForm.timeZone = 'W. Europe Standard Time';
    }
  }

  onLocationSelected(e: any) {
    if (e.node.key) {
      this.employeeForm.locationId = e.node.key;
    }
  }

  onTrainingGroupSelected(e: any) {
    if (e.node.selected) {
      this.employeeForm.trainingGroupIds = [
        ...this.employeeForm.trainingGroupIds,
        e.node.key,
      ];
    } else {
      this.employeeForm.trainingGroupIds =
        this.employeeForm.trainingGroupIds.filter((id) => id !== e.node.key);
    }
  }

  onAvatarUploaded(files: any) {
    const file = files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.employeeForm!.imageBase64 = reader!.result!.toString();
    };
    reader.readAsDataURL(file);
  }

  patchForm(person: PersonFullModel) {
    this.employeeForm.firstName = person.person.firstName;
    this.employeeForm.lastName = person.person.lastName;
    this.employeeForm.locationId = person.person.location.id;
    this.employeeForm.roleId = person.person.role.id;
    this.employeeForm.culture = person.person.culture;
    this.employeeForm.timeZone = person.person.timeZone;
    this.employeeForm.email = person.person.email;
    this.employeeForm.iddNumber = person.person.iddNumber || null;
    this.employeeForm.phonePrivate = person.person.phonePrivate;
    this.employeeForm.phoneMobile = person.person.phoneMobile;
    this.employeeForm.phoneBusiness = person.person.phoneBusiness;
    this.employeeForm.agentNumber = person.person.agentNumber;
    this.employeeForm.street = person.person.street;
    this.employeeForm.zipCode = person.person.zipCode;
    this.employeeForm.city = person.person.city;
    this.employeeForm.emailSignature = person.person.emailSignature;
    this.employeeForm.trainingGroupIds = person.trainingGroups.map(
      (tg) => tg.id
    );
    this.employeeForm.createdAt = person.person.createdAt
      ? new Date(person.person.createdAt)
      : null;
    this.employeeForm.status = person.person.inactive ? 'Inactive' : 'Active';
    this.isInactive = person.person.inactive;

    if (person.user) {
      this.employeeForm.accessGranted = person.user!.createdAt!
        ? new Date(person.user!.createdAt!)
        : null;
    } else {
      this.employeeForm.accessGranted = null;
    }

    if (person.person.image) {
      this.loadingService
        .load(this.fileUtilService.avatarUrlToBase64(person.person.image!))
        .subscribe((base64) => {
          const mimeType = this.getImageMimeType(person.person.image!);
          this.employeeForm.imageBase64 = `data:${mimeType};base64,${base64}`;
        });
    }

    this.setTreeViewValues();
    //this.filterDepartments();
  }

  setTreeViewValues() {
    this.trainingGroups.forEach((trainingGroup) => {
      if (this.employeeForm.trainingGroupIds.indexOf(trainingGroup.id) > -1) {
        trainingGroup.selected = true;
      }
    });
  }

  patchFormUser(user: UserModel) {
    this.isUserHasAccount = user.createdAt != null;
  }

  onTabChange(e: DxTabsTypes.ItemClickEvent) {
    this.selectedTab = e.itemData.id;
  }

  getImageMimeType(url: string) {
    const extension = url.split('.').pop()?.split(/\#|\?/)[0].toLowerCase();

    switch (extension) {
      case 'png':
        return 'image/png';
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg';
      case 'gif':
        return 'image/gif';
      default:
        return 'image/jpeg';
    }
  }

  refreshEvents() {
    this.refreshComponent(true);
  }

  togglePersonState(state: string) {
    if (this.editingPersonId) {
      const result = this.personService.togglePersonState(
        this.editingPersonId,
        state
      );
      this.loadingService.load(result).subscribe(() => {
        if (state == 'inactive')
          this.toastrService.success('Employee status was set to inactive!');
        else this.toastrService.success('Employee status was set to active!');

        this.refreshComponent();
      });
    }
  }

  refreshComponent(isLoadTrainings: boolean = false) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      const routeConfig: any = {};

      if (isLoadTrainings) {
        routeConfig.queryParams = { tab: 'trainings' };
      }

      this.router.navigate(
        ['/employee/edit/' + this.editingPersonId],
        routeConfig
      );
    });
  }

  openAddUserConfirmationModal() {
    this.currentModalID = 'addUser';
    this.modalOptions = {
      hideUndo: true,
      submitButtonText: this.translateService.instant('COMMON.YES'),

      message: this.translateService.instant(
        'EMPLOYEE.FORM.CONFIRM-SEND-LOGIN-DETAILS'
      ),
      dismissButtonText: this.translateService.instant('COMMON.NO'),
    };
    this.showModal = true;
  }

  openChangeUserPasswordConfirmationModal() {
    this.currentModalID = 'changePassword';
    this.modalOptions = {
      hideUndo: true,
      submitButtonText: this.translateService.instant('COMMON.YES'),
      message: this.translateService.instant(
        'EMPLOYEE.FORM.CONFIRM-RESET-PASSWORD-FOR-EMPLOYEE'
      ),
      dismissButtonText: this.translateService.instant('COMMON.NO'),
    };
    this.showModal = true;
  }

  modalSubmit() {
    switch (this.currentModalID) {
      case 'addUser':
        this.addUser();
        return;
      case 'changePassword':
        this.changeUserPassword();
        return;
      default:
        break;
    }

    this.currentModalID = null;
  }

  addUser() {
    if (this.editingPersonId) {
      const model: ManageUserModel = {
        personId: this.editingPersonId,
        newPassword: null,
        newEmail: null,
      };

      this.loadingService
        .load(this.userService.addUserByPersonId(model))
        .subscribe(() => {
          this.toastrService.success('Zugangsdaten wurden versendet');
          this.refreshComponent();
        });
    }
  }

  changeUserPassword() {
    if (this.editingPersonId) {
      const model: ManageUserModel = {
        personId: this.editingPersonId,
        newPassword: null,
        newEmail: null,
      };

      this.loadingService
        .load(this.userService.changeUserPasswordByPersonId(model))
        .subscribe(() => {
          this.toastrService.success('Kennwort wurde zurückgesetzt');
          this.refreshComponent();
        });
    }
  }

  showOverviewPopup() {
    this.isOverviewPopupVisible = true;
  }

  onOverviewPopupClose() {
    this.isOverviewPopupVisible = false;
  }
}
