import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoadingService } from '../../services/loading.service';
import { FileUtilService } from '../../services/file-util.service';
import { TrainingAnonymousService } from '../../services/training-anonymous.service';
import { TrainingAnonymousModel } from '../../models/training/training-anonymous.model';
import { TrainingLocationTypeModel } from '../../models/training/training-location-type.model';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
import { calendarIcon } from '../../app-icons';

@Component({
  selector: 'app-my-invitation',
  templateUrl: './my-invitation.component.html',
  styleUrls: ['./my-invitation.component.scss'],
})
export class MyInvitationComponent {
  trainingKey: string;
  accessToken: string;
  TrainingLocationType = TrainingLocationTypeModel;
  training: TrainingAnonymousModel | null = null;
  showAcceptInvitationModal: boolean = false;
  isInvitationAccepted: boolean = false;
  location: { latitude: number; longitude: number } | null = null;

  get locationType() {
    switch (this.training?.locationType) {
      case TrainingLocationTypeModel.Address:
        return this.translateService.instant(
          'COMMON.FIELDS.EVENT-TYPES.FACE-TO-FACE'
        );
      case TrainingLocationTypeModel.Web:
        return this.translateService.instant(
          'COMMON.FIELDS.EVENT-TYPES.ONLINE'
        );
      case TrainingLocationTypeModel.GoToWebinar:
        return 'GoToWebinar';
      case TrainingLocationTypeModel.Webex:
        return 'Webex';
      case TrainingLocationTypeModel.MsTeams:
        return 'MsTeams';
      default:
        return '';
    }
  }

  get durationString() {
    if (
      !this.training ||
      typeof this.training?.iddTime !== 'number' ||
      isNaN(this.training.iddTime)
    ) {
      return '00:00 h';
    }

    const hours = Math.floor(this.training?.iddTime / 60);
    const remainingMinutes = this.training?.iddTime % 60;
    const durationString = `${hours}:${
      remainingMinutes < 10 ? '0' : ''
    }${remainingMinutes} h`;

    return durationString;
  }

  constructor(
    private route: ActivatedRoute,
    private trainingAnonymousService: TrainingAnonymousService,
    private loadingService: LoadingService,
    public translateService: TranslateService,
    private cookieService: CookieService,
    private toastrService: ToastrService,
    private fileUtilService: FileUtilService,
    private sanitized: DomSanitizer
  ) {
    this.trainingKey = this.route.snapshot.params['key'];
    this.accessToken = this.route.snapshot.params['token'];

    this.loadTrainingData(this.accessToken);
  }

  getCalendarIcon() {
    return this.sanitized.bypassSecurityTrustHtml(calendarIcon);
  }

  loadTrainingData(accessToken: string | null = null): void {
    this.loadingService
      .load(
        this.trainingAnonymousService.getTrainingAnonymous(this.trainingKey, accessToken)
      )
      .subscribe((training) => {
        this.training = training;

        if (training.latitude && training.longitude) {
          this.location = {
            latitude: training.latitude,
            longitude: training.longitude,
          };
        };

        const trainingCookieValue = this.cookieService.get(
          `training-${this.trainingKey}`
        );

        if (trainingCookieValue === 'true') {
          this.isInvitationAccepted = true;
        };

        if (this.training.memberStatus == 1) {
          this.isInvitationAccepted = true;
        };
      });
  }

  onInviteAcepted(): void {
    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 1);

    this.cookieService.set(
      `training-${this.trainingKey}`,
      'true',
      expirationDate,
      '/'
    );
    this.isInvitationAccepted = true;

    if (this.accessToken) {
      this.loadTrainingData(this.accessToken);
    }
  }

  onAcceptInvitationClick(): void {
    this.showAcceptInvitationModal = true;
  }

  onDeclineInvitationClick(): void {
    this.cookieService.delete(`training-${this.trainingKey}`, '/');
    this.isInvitationAccepted = false;
  }

  onDownloadIcs(): void {
    this.loadingService
      .load(
        this.trainingAnonymousService.getTrainingAnonymousIcs(this.trainingKey)
      )
      .subscribe({
        next: (ics) => this.fileUtilService.downloadFile(ics),
        error: () =>
          this.toastrService.error(
            this.translateService.instant('COMMON.AN-ERROR-HAS-OCCURRED')
          ),
      });
  }
}
